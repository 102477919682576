import { useEffect, useRef, useState } from "react";
import { getMapStore } from "../map/Map.repository";
import { useObservable } from "@ngneat/use-observable";
import LayerGroup from "ol/layer/Group";
import { Tooltip } from "antd";
import { Layer } from "ol/layer";
import { Control } from "ol/control";

export const BaseLayerSwitcher = () => {
	const [expanded, setExpanded] = useState(false);
	const [toggle, setToggler] = useState(false);
	const [mapStore] = useObservable(getMapStore());
	const switcherRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!switcherRef.current || !mapStore.map) return;

		const switcherControl = new Control({ element: switcherRef.current });

		mapStore.map.addControl(switcherControl);
	}, [switcherRef, mapStore]);

	if (!mapStore.map) return <></>;

	const layerGroup = mapStore.map
		.getLayers()
		.getArray()
		.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");

	const toggleBaselayer = (layer: Layer) => {
		layerGroup?.getLayersArray().forEach((l) => l.setVisible(false));
		layer.setVisible(true);
		setToggler(!toggle);
		mapStore.map?.changed();
	};

	return (
		<div ref={switcherRef} className={"-z-20 bottom-11 left-2 sm:left-4 right-auto absolute drop-shadow-md"}>
			<div className="bg-white rounded-md p-2 relative shadow-md">
				<div className="max-w-[85vw] overflow-x-auto flex gap-x-3 p-1">
					{layerGroup
						?.getLayersArray()
						.filter((l) => expanded || l.getVisible())
						.map((l) => (
							<Tooltip
								key={l.get("id")}
								title={l.get("title")}
								overlayClassName="font-bold "
								color="#4d4f50"
								overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center", maxWidth: "12rem" }}
							>
								<button
									onClick={() => (expanded ? toggleBaselayer(l) : setExpanded(true))}
									className={`rounded-md transition-all hover:ring-npp-blue-500 ring-npp-grey-300 hover:ring-4 ring-2 relative  flex-none ${
										l.getVisible() && expanded && "ring"
									}`}
								>
									<img src={l.get("thumbnail")} className="rounded-md" alt="" />
								</button>
							</Tooltip>
						))}
				</div>
				<div className="absolute  inset-y-0 right-0 flex items-center">
					<button className={`absolute bg-white/90 h-[42px] flex w-[27px] rounded-r-md items-center justify-center`} onClick={() => setExpanded(!expanded)}>
						<span className={`material-icons !text-base text-neutral-400 antialiased ${expanded ? "rotate-180" : ""}`}>keyboard_double_arrow_right</span>
					</button>
				</div>
			</div>
		</div>
	);
};
