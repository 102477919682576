import { useEffect } from "react";
import * as ol from "ol/util";
import VectorSource from "ol/source/Vector";
import WKT from "ol/format/WKT";

let UPDATE = true;

interface Storage {
	[key: string]: {
		geom: string;
		uid: string;
	}[];
}

let list1: any;
let list2: any;
let list3: any;
let storage: Storage;

export const MapMarkerStorageManager = ({ source, objectId }: { source: VectorSource; objectId: string }) => {
	useEffect(() => {
		updateStorage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectId]);

	useEffect(() => {
		list1 = source.on("changefeature", () => updateStorage());
		list2 = source.on("addfeature", () => updateStorage());
		list3 = source.on("removefeature", () => updateStorage());
		return () => {
			[list1, list2, list3].forEach((l) => source.un(l.type, l.listener));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectId]);

	const updateStorage = () => {
		if (UPDATE) {
			const items = source.getFeatures().map((f) => ({
				geom: new WKT().writeGeometry(f.getGeometry()!),
				uid: ol.getUid(f),
			}));
			storage = { [objectId || "new"]: items };
			localStorage.setItem("lvc-geom", JSON.stringify(storage));
		}
	};

	useEffect(() => {
		window.addEventListener("storage", onStorageUpdate);
		return () => {
			window.removeEventListener("storage", onStorageUpdate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectId]);

	const onStorageUpdate = () => {
		UPDATE = false;
		setTimeout(() => {
			UPDATE = true;
		}, 500);
		const string = localStorage.getItem("lvc-geom");
		if (string && string !== JSON.stringify(storage)) {
			const features = (JSON.parse(string) as Storage)[objectId || "new"]?.map((i: { geom: string; uid: string }) => {
				const f = new WKT().readFeature(i.geom);
				f.setId(i.uid);
				return f;
			});

			if (features) {
				storage = JSON.parse(string);
				source.clear();
				source.addFeatures(features);
			}
		}
	};

	return <></>;
};
