import { Map } from "ol";
import { MapMarkerPointControl } from "./Map-marker-point-control";
import { MapMarkerStorageManager } from "./Map-marker-storage-manager";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { MapMarkerLineControl } from "./Map-marker-line-control";
import { MapMarkerPolyControl } from "./Map-marker-poly-control";
import { MapUndoRedoControl } from "./Map-undo-redo-control";
import { useEffect, useState } from "react";
import { MapMarkerDetourLineControl } from "./Map-marker-detour-line";

export interface MapDrawControlProps {
	map?: Map;
	layer?: VectorLayer<VectorSource>;
	objectId?: string;
	type?: string;
	disable?: boolean;
	detour?: boolean;
}

export const MapMarkerDrawControls = ({ map, layer, objectId, type, disable, detour }: MapDrawControlProps) => {
	const [state, setState] = useState("");

	const checkTypes = (source: VectorSource) => {
		const items = source.getFeatures().map((f) => f.getGeometry()?.getType());
		setState(items[0] ?? "");
	};

	useEffect(() => {
		if (layer) {
			const source = layer.getSource()!;
			checkTypes(source);
			source.on("change", () => checkTypes(source));
		}
	}, [layer]);

	return (
		<>
			{map && layer && (
				<div className="relative space-y-2 z-10 rounded-md flex flex-col">
					<MapMarkerStorageManager source={layer.getSource()!} objectId={objectId ?? "new"}></MapMarkerStorageManager>
					{detour && <MapMarkerDetourLineControl source={layer.getSource()!} map={map} readOnly={disable}></MapMarkerDetourLineControl>}
					<MapMarkerPointControl source={layer.getSource()!} map={map} readOnly={state === "MultiLineString" || disable}></MapMarkerPointControl>
					{type !== "i" && (
						<div className="relative space-y-2 z-10 rounded-md flex flex-col">
							<MapMarkerLineControl source={layer.getSource()!} map={map} readOnly={state === "Point" || disable}></MapMarkerLineControl>
							<MapMarkerPolyControl source={layer.getSource()!} map={map} readOnly={state === "Point" || disable}></MapMarkerPolyControl>
						</div>
					)}
					<MapUndoRedoControl></MapUndoRedoControl>
				</div>
			)}
		</>
	);
};
