import { Map, Overlay } from "ol";
import { useEffect, useRef } from "react";
import { BUBBLE_OVERLAYS, BubbleResponse } from "./Bubble-wrapper";
import WKT from "ol/format/WKT";
import { Point } from "ol/geom";
import { t } from "i18next";

interface BubbleProps {
	map: Map;
	bubble: BubbleResponse;
	add: boolean;
}

export const Bubble = ({ map, bubble, add }: BubbleProps) => {
	const overlayRef = useRef<HTMLDivElement | null>(null);

	// bubble.stats = bubble.stats.map((s, i) => ({ ...s, count: i === 1 ? 0 : Number(Math.random().toFixed(2)) * 100 }));

	useEffect(() => {
		createOverlay();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [overlayRef]);

	const createOverlay = () => {
		if (!overlayRef.current) return;

		const overlay = new Overlay({
			element: overlayRef.current,
			position: (new WKT().readGeometry(bubble.center, { dataProjection: "EPSG:3059", featureProjection: "EPSG:3857" }) as Point).getCoordinates(),
			positioning: "center-center",
			className: "ol-unselectable !pointer-events-none",
		});

		overlay.set("group", "bubble");

		BUBBLE_OVERLAYS.push(overlay);

		if (add) map.addOverlay(overlay);
	};

	const total = bubble.stats.map((s) => s.count).reduce((a, b) => a + b);

	let status = "gray";

	if (status !== "gray" && total === 0) status = "gray";
	if (status !== "green" && total !== 0 && total <= 70) status = "green";
	if (status !== "yellow" && total > 70 && total <= 130) status = "yellow";
	if (status !== "red" && total > 130) status = "red";

	const getColor = (opacity: number) => {
		switch (status) {
			case "gray":
				return `rgb(76, 78, 79, ${opacity.toString()})`;
			case "green":
				return `rgb(64, 147, 111, ${opacity.toString()})`;
			case "yellow":
				return `rgb(235, 141, 0, ${opacity.toString()})`;
			case "red":
				return `rgb(229, 19, 53, ${opacity.toString()})`;
		}
	};

	const getLabel = () => {
		switch (status) {
			case "gray":
				return (
					<>
						<h1 className="text-2xl font-bold text-center uppercase">{t("bubbleMessageNone")}</h1>
						<p className="text-center mb-6 text-lg lowercase">{t("bubbleMessageEvents")}</p>
					</>
				);
			case "green":
				return (
					<>
						<h1 className="text-2xl font-bold text-center uppercase">{t("bubbleMessageFew")}</h1>
						<p className="text-center mb-6 text-lg lowercase">{t("bubbleMessageEventsOpt")}</p>
					</>
				);
			case "yellow":
				return (
					<>
						<h1 className="text-2xl font-bold text-center uppercase">{t("bubbleMessageMedium")}</h1>
						<p className="text-center mb-6 text-lg lowercase">{t("bubbleMessagesAmountOfEvents")}</p>
					</>
				);
			case "red":
				return (
					<>
						<h1 className="text-2xl font-bold text-center uppercase">{t("bubbleMessageMany")}</h1>
						<p className="text-center mb-6 text-lg lowercase">{t("bubbleMessageEventsOpt2")}</p>
					</>
				);
		}
	};

	return (
		<div style={{ backgroundColor: getColor(0.2) }} ref={overlayRef} className="p-6 rounded-full flex !pointer-events-none">
			<div style={{ backgroundColor: getColor(0.5) }} className="rounded-full text-white font-semibold aspect-square flex flex-col items-center justify-center h-72">
				{getLabel()}
				<ul className="space-y-2 text-lg w-64">
					{status !== "gray" &&
						bubble.stats
							.filter((s) => s.count !== 0)
							.map((s, i) => (
								<li key={i} className="grid grid-cols-4 gap-2 mr-8">
									<p className="font-bold flex justify-end items-center">{s.count.toFixed(0)}</p>
									<div className="flex justify-center items-center">
										<img src={s.icon} alt="icon" className="h-10 w-10" />
									</div>
									<p className="col-span-2 flex items-center">{s.name}</p>
								</li>
							))}
				</ul>
			</div>
		</div>
	);
};
