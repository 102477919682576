import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../../../map/Map.repository";
import { useEffect } from "react";
import { Feature } from "ol";
import VectorImageLayer from "ol/layer/VectorImage";
import Cluster from "ol/source/Cluster";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { checkIfVisible, getCoolerClusterStyle } from "./Cooler-cluster-style";
import { getFeatureState, setFeatureState } from "ol-mapbox-style";
import { CoolerClusterHighlight } from "./Cooler-cluster-highlighter";
import { BehaviorSubject } from "rxjs";
import { DETOUR_LIST } from "../../Data-layers-new-layer-manager";
import { isMobile } from "react-device-detect";

export const COOLER_CLUSTER_SOURCE = new VectorSource();
export let CLUSTER: any;
export let CLUSTERED$ = new BehaviorSubject<Set<string>>(new Set());

export const CoolerCluster = () => {
	const [mapStore] = useObservable(getMapStore());
	const map = mapStore.map;

	useEffect(() => {
		if (!map) return;

		const cluster = new VectorImageLayer({
			zIndex: 2,
			maxZoom: 30,
			properties: {
				name: "Cluster",
				id: "cluster",
			},
			source: new Cluster({
				distance: 96,
				minDistance: 96,
				source: COOLER_CLUSTER_SOURCE,
				geometryFunction: (feature) => {
					const geom = feature.getGeometry()!;
					if (geom instanceof Point && !feature.get("removed") && feature.get("internal")?.["style"]) {
						const l = checkIfVisible(feature.get("internal")?.["layerName"], map);
						if (!!l) {
							const [style] = l.points;
							feature.set("opacity", style.opacity, true);
							return geom;
						}
					}
					return null;
				},
				createCluster: (point, features) => {
					if (features.length === 1) {
						const [feature] = features;
						const detour = DETOUR_LIST[feature.get("kafka_id")];
						if (detour) {
							const properties = { ...feature.getProperties() };
							delete properties.geometry;
							delete properties.layer;
							detour.setProperties({
								internal: {
									...properties.internal,
									attributes: ["detour"],
								},
								visible: true,
							});
						}
						const props = feature.getProperties();
						const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
						if (layer) {
							setFeatureState(
								layer as any,
								{ id: props["linked_to"] + "-" + props["layer"], source: props["layer"] },
								props["internal"]["highlighted"] ? { highlighted: true } : undefined
							);
						}
						return new Feature({
							point: new Point([0, 0]),
						});
					}

					features.forEach((f) => {
						const detour = DETOUR_LIST[f.get("kafka_id")];
						if (detour) {
							detour.set("visible", false);
						}
						const props = f.getProperties();
						const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
						if (layer && !getFeatureState(layer as any, { id: props["linked_to"], source: props["layer"] })) {
							setFeatureState(layer as any, { id: props["linked_to"] + "-" + props["layer"], source: props["layer"] }, { cluster: true });
						}
					});

					return new Feature({
						geometry: point,
						features: features,
					});
				},
			}),
			style: (f) => getCoolerClusterStyle(f as any, map.getView().getRotation()),
		});

		CLUSTER = cluster;
		map.addLayer(cluster);
	}, [map]);

	return map && !isMobile ? <CoolerClusterHighlight map={map}></CoolerClusterHighlight> : <></>;
};
