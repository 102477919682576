import { initReactI18next } from "react-i18next";
import { GlobalSettings } from "./Settings";
import { TRANSLATIONS } from "./shared/translations";
import i18n from "i18next";
import { IS_LOCALHOST } from "./map/Endpoints";

i18n.use(initReactI18next).init({
	resources: TRANSLATIONS,
	lng: "lv",
	fallbackLng: "lv",
	interpolation: {
		escapeValue: false,
	},
});

if (!IS_LOCALHOST) console.warn = function filterWarnings(msg, ...args) {};

function App() {
	return <GlobalSettings /* name={"unexpectedEvent"} ids={["658e31e7428c8fe61ca7396f", "658e31e4428c8fe61ca73942"]} */></GlobalSettings>;
}

export default App;
