import { Feature, Map, View } from "ol";
import { Point } from "ol/geom";
import { Modify } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { MapDrawControlProps } from "./controls/Map-marker-draw-controls";
import proj4 from "proj4";
import * as ol from "ol/util";
import { register } from "ol/proj/proj4";
import { getDrawStyle } from "./controls/Draw-styles";
import axios from "axios";
import { LayerResponse } from "../store/Layers.repository";
import { ENTRYPOINT } from "../map/Endpoints";
import { Map as MapboxMap } from "mapbox-gl";
import Layer from "ol/layer/Layer.js";
import Source from "ol/source/Source.js";
import { mapSize$ } from "./Map-marker-feature-list";

/** Component for showing markers on map with the provided coordinates */

interface MapMarkerProps {
	onChange(coord: [number, number]): unknown; // Emits [Lon, Lat] when marker is modified (dragged)
	setValue: Dispatch<SetStateAction<MapDrawControlProps>>;
	children?: any;
	value?: [number, number] | null; // [Lon, Lat] Changing this, changes markers location
}

export const MARKER_LAYER = new VectorLayer({
	source: new VectorSource(),
	zIndex: Infinity,
	style: (f, r) => getDrawStyle(f as Feature, r),
});

const createMap = () => {
	return new Map({
		view: new View({
			center: fromLonLat([24.1056, 56.9677]),
			zoom: 8,
			maxZoom: 19,
		}),
		layers: [MARKER_LAYER],
	});
};

export const MAP = createMap();

const MODIFY = new Modify({
	source: MARKER_LAYER.getSource()!,
});

export const AddProjection = () => {
	proj4.defs("EPSG:3059", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
	register(proj4);
};

export default function MapMarker(props: MapMarkerProps) {
	const mapElement = useRef<HTMLDivElement | null>(null);

	// Create map
	useEffect(() => {
		if (!mapElement.current) return;

		MAP.setLayers([MARKER_LAYER]);

		axios
			.get<{
				base: LayerResponse[];
			}>(ENTRYPOINT + "/layer/layers")
			.then((res) => {
				const layerDefinition = res.data.base.find((l) => l.enabled);

				AddProjection();

				if (layerDefinition) {
					const mbMap = new MapboxMap({
						style: layerDefinition.parameters,
						attributionControl: false,
						boxZoom: false,
						container: mapElement.current!,
						doubleClickZoom: false,
						dragPan: false,
						maxZoom: 19,
						dragRotate: false,
						interactive: false,
						keyboard: false,
						pitchWithRotate: false,
						scrollZoom: false,
						touchZoomRotate: false,
					});

					const mbLayer = new Layer({
						zIndex: -1000,
						render: function (frameState) {
							const canvas = mbMap.getCanvas();
							const viewState = frameState.viewState;

							const visible = mbLayer.getVisible();
							canvas.style.display = visible ? "block" : "none";
							canvas.style.position = "absolute";

							const opacity = mbLayer.getOpacity();
							//@ts-ignore
							canvas.style.opacity = opacity;

							// adjust view parameters in mapbox
							const rotation = viewState.rotation;
							mbMap.jumpTo({
								center: toLonLat(viewState.center) as any,
								zoom: viewState.zoom - 1,
								bearing: (-rotation * 180) / Math.PI,
							});

							// NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
							//@ts-ignore
							if (mbMap._frame) {
								//@ts-ignore
								mbMap._frame.cancel();
								//@ts-ignore
								mbMap._frame = null;
							} //@ts-ignore
							mbMap._render();

							return canvas;
						},
						source: new Source({
							attributions: [
								'<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>',
								'<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
							],
						}),
					});

					if (window.location.search.includes("?location=")) {
						const location = decodeURI(window.location.search)
							.replace("?location=", "")
							.replaceAll("%2C", ",")
							.split(",")
							.map((s) => Number(s));
						const view = MAP.getView();

						view.setCenter([location[0], location[1]]);
						view.setZoom(location[2]);

						window.history.replaceState(null, "", window.location.pathname);
					}

					MAP.addLayer(mbLayer);
					MAP.setTarget(mapElement.current!);
					if (!!MAP.getSize()) mapSize$.next(MAP.getSize()! as [number, number]);
					MAP.addInteraction(MODIFY);

					props.setValue({ layer: MARKER_LAYER, map: MAP });

					MODIFY.on("modifystart", (event) => {
						const [feature] = event.features.getArray();
						feature.set("transformedCoords", undefined);
					});

					MODIFY.on("modifyend", (event) => {
						const [feature] = event.features.getArray();

						//feature.set("trigger", !feature.get("trigger")); //Used to trigget map marker linear ref
						const f = MARKER_LAYER.getSource()!.getFeatureByUid(ol.getUid(feature));
						f?.set("trigger", !f.get("trigger"));

						if (feature) {
							props.onChange(toLonLat((feature.getGeometry() as Point).getCoordinates()) as [number, number]);
						}
					});
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapElement]);

	// Add marker on prop coord change
	useEffect(() => {
		if (props.value) {
			const feature = new Feature({
				geometry: new Point(fromLonLat(props.value)),
			});
			const source = MARKER_LAYER.getSource()!;
			source.clear();
			source.addFeature(feature);

			MAP.getView().fit(feature.getGeometry()!, {
				padding: [100, 100, 100, 100],
				maxZoom: 12,
				duration: 500,
			});
		}
	}, [props.value]);

	return (
		<div ref={mapElement} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
			{props.children}
		</div>
	);
}
