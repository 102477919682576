export default function Tag4Component(props: { text: string; icon?: boolean }) {
	return (
		<label className="px-4 py-1 bg-white rounded-sm border-2 border-npp-grey-500 flex justify-evenly items-center text-[#56595a] hover:invert hover:bg-[#b2b0af] hover:boder-[#b2b0af] hover:text-black">
			{props.icon && (
				<svg width="16px" height="16px" fill="#fffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
					<path
						d="M24.85,19.15l-10-10A7.39,7.39,0,0,0,15,7.5,7.5,7.5,0,1,0,7.5,15a7.43,7.43,0,0,0,2.2-.33l1.24,1.2a.5.5,0,0,0,.35.14H13v2.5a.5.5,0,0,0,.5.5H16v2.5a.5.5,0,0,0,.5.5H19v2.5a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-5A.5.5,0,0,0,24.85,19.15ZM6,4A2,2,0,1,1,4,6,2,2,0,0,1,6,4Z"
						fill="#fffff"
					/>
				</svg>
			)}
			{props.text}
		</label>
	);
}
