import { useObservable } from "@ngneat/use-observable";
import { Map, MapBrowserEvent } from "ol";
import { EventsKey } from "ol/events";
import { useState } from "react";
import { getMapStore } from "../Map.repository";

let key: EventsKey | undefined;

export default function CenterPoint() {
	const [select, setSelect] = useState(false);
	const [store] = useObservable(getMapStore());
	const map = store.map;

	const centerOnClick = (e: MapBrowserEvent<any>) => {
		map!.getView().setCenter(e.coordinate);
		setSelect(false);
		key = undefined;
	};

	function setEvent(map: Map) {
		setSelect(!select);
		if (!key) {
			key = map.once("click", centerOnClick);
		} else {
			map.un("click", key!.listener);
			key = undefined;
		}
	}

	return (
		<div className={"ol-control ol-zoom z-10 top-40 hidden"} onClick={() => setEvent(map!)}>
			<button className={"hover:bg-orange-200 " + (select ? "!bg-orange-500 !text-white hover:!bg-orange-600 " : "")}>·</button>
		</div>
	);
}
