/* eslint-disable @typescript-eslint/no-unused-vars */
import { MapMarkerWrapper } from "./Map-marker-wrapper";
import { MapMarkerFeatureList } from "./Map-marker-feature-list";
import { MapMarkerOpenTab } from "./Map-marker-open-tab";
import { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { GlobalSettings } from "../Settings";

const ITEM = [
	{
		street: "A2_K",
		start: "14.13147294638733",
		geom: "POINT(522975.8866463631 312853.86587327067)",
		openlr: {
			binary: "test",
		},
	},
];

/* Mini map testing grounds */
export const MapMarkerForm = () => {
	const [options, setOptions] = useState<{ data: any; readOnly: boolean; id: string; sticky: boolean; onlyOne: boolean; visible: boolean; mapMode: 0 | 1 | 2 }>({
		data: [
			{
				geom: "MULTILINESTRING((534092.5968473649 306715.6971964408,536713.8359338817 312994.18419643585,527582.9645789769 323371.090808928))",
				openlr: {},
				error: false,
				detour: false,
				twoWay: false,
			},
			{
				geom: "MULTILINESTRING((522231.8179284148 311432.6023747213,527582.9645789769 323371.090808928))",
				openlr: {},
				error: false,
				detour: true,
				twoWay: true,
				turnedAround: true,
			},
		],
		readOnly: false,
		id: "10",
		sticky: false,
		onlyOne: false,
		visible: true,
		mapMode: 0,
	});

	return (
		<div className="flex items-center justify-center gap-12 inset-0 absolute">
			<div className="gap-2 flex absolute top-10 flex-wrap">
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							data: [
								{
									street: "A2_K",
									start: "14.13147294638733",
									geom: "POINT(522975.8866463633 312853.86587327253)",
								},
							],
						});
					}}
				>
					SET_DATA
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							data: [
								{
									street: "P36_I",
									start: "86.557",
									geom: "POINT(667445.0000000002 338454.5)",
									openlr: {
										binary: "Iyq/Eg7/7A==",
										latitude: 21.093299858309102,
										longitude: 60.11205965637826,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>60.11205965637826</loc:latitude><loc:longitude>21.093299858309102</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P126_I",
									start: "29.216",
									geom: "POINT(426167.99999999977 373610.5)",
									openlr: {
										binary: "Iyk3KA+MOA==",
										latitude: 21.863974165424704,
										longitude: 57.959225060732805,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>57.959225060732805</loc:latitude><loc:longitude>21.863974165424704</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P125_I",
									start: "10.476",
									geom: "POINT(411133.2500000006 354483.24999999907)",
									openlr: {
										binary: "IykdiA9TGg==",
										latitude: 21.550232385859918,
										longitude: 57.81846565126151,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>57.81846565126151</loc:latitude><loc:longitude>21.550232385859918</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P27_I",
									start: "58.82",
									geom: "POINT(663301.4999999997 342371.24999999907)",
									openlr: {
										binary: "Iyq4lQ8NUA==",
										latitude: 21.16686597645742,
										longitude: 60.076427694224556,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>60.076427694224556</loc:latitude><loc:longitude>21.16686597645742</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									geom: "POINT(617588.5062684538 382350.5095235128)",
									openlr: {
										binary: "IypvQw+V6Q==",
										latitude: 21.917228193235253,
										longitude: 59.673660427212965,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>59.673660427212965</loc:latitude><loc:longitude>21.917228193235253</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P37_I",
									start: "89.357",
									geom: "POINT(665051.0000000002 338404.49999999907)",
									openlr: {
										binary: "Iyq7KA8AGg==",
										latitude: 21.0942941257148,
										longitude: 60.09057155917936,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>60.09057155917936</loc:latitude><loc:longitude>21.0942941257148</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V169_I",
									start: "7.802",
									geom: "POINT(564307.4999999993 405156.7499999963)",
									openlr: {
										binary: "IyoaWQ/itQ==",
										latitude: 22.339082567552282,
										longitude: 59.20722032193438,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>59.20722032193438</loc:latitude><loc:longitude>22.339082567552282</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P15_I",
									start: "1.079",
									geom: "POINT(522201.4999999997 413320.75)",
									openlr: {
										binary: "IynV2g//vQ==",
										latitude: 22.498555820227445,
										longitude: 58.83096068614215,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.83096068614215</loc:latitude><loc:longitude>22.498555820227445</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V944_I",
									start: "9.568",
									geom: "POINT(607637.4999999994 285042.75)",
									openlr: {
										binary: "IypZDQ5cmQ==",
										latitude: 20.196149440235825,
										longitude: 59.55166338347234,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>59.55166338347234</loc:latitude><loc:longitude>20.196149440235825</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V26_I",
									start: "2.7",
									geom: "POINT(514034.0000000009 300669.49999999907)",
									openlr: {
										binary: "IynBtw6eXQ==",
										latitude: 20.557415229299707,
										longitude: 58.72035204797695,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.72035204797695</loc:latitude><loc:longitude>20.557415229299707</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "P4_I",
									start: "5.726",
									geom: "POINT(514872.0000000001 311144.99999999534)",
									openlr: {
										binary: "IynD8Q6/HA==",
										latitude: 20.737283294847995,
										longitude: 58.73256780681971,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.73256780681971</loc:latitude><loc:longitude>20.737283294847995</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V1_I",
									start: "0",
									geom: "POINT(508888.74999999977 307348.49999999534)",
									openlr: {
										binary: "Iym53g60IA==",
										latitude: 20.67693868880233,
										longitude: 58.67724709039531,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.67724709039531</loc:latitude><loc:longitude>20.67693868880233</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V1_I",
									start: "0",
									geom: "POINT(508888.74999999977 307348.49999999534)",
									openlr: {
										binary: "Iym53g60IA==",
										latitude: 20.67693868880233,
										longitude: 58.67724709039531,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.67724709039531</loc:latitude><loc:longitude>20.67693868880233</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "V58_I",
									start: "1.72",
									geom: "POINT(550481.4999999995 331997.5)",
									openlr: {
										binary: "Iyn/pg77sA==",
										latitude: 21.070044505049346,
										longitude: 59.0605639790122,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>59.0605639790122</loc:latitude><loc:longitude>21.070044505049346</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "A2_L",
									start: "38.718",
									geom: "POINT(539848.5000000001 331004.99999999907)",
									openlr: {
										binary: "IynuOA76Bg==",
										latitude: 21.060911240177838,
										longitude: 58.96480705118165,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>58.96480705118165</loc:latitude><loc:longitude>21.060911240177838</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
								{
									street: "A2_I",
									start: "51.932",
									geom: "POINT(552567.9999999992 334170.24999999534)",
									openlr: {
										binary: "IyoDNg8CSg==",
										latitude: 21.10630543618506,
										longitude: 59.08012795229455,
										locationreference_xml:
											"<sit:locationReference xsi:type='loc:PointLocation'><loc:openlrPointLocationReference xsi:type='loc:OpenlrGeoCoordinate'><loc:openlrCoordinates><loc:latitude>59.08012795229455</loc:latitude><loc:longitude>21.10630543618506</loc:longitude></loc:openlrCoordinates></loc:openlrPointLocationReference></sit:locationReference>",
									},
									error: false,
								},
							],
						});
					}}
				>
					SET_BIG_DATA
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							id: "123",
						});
					}}
				>
					SET_ID
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm relative"
					onClick={() => {
						setOptions({
							...options,
							sticky: !options.sticky,
						});
					}}
				>
					SET_STICKY
					{options.sticky && (
						<div className="absolute top-10 left-0 right-0">
							<CheckCircleOutlined rev={undefined} />
						</div>
					)}
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							readOnly: !options.readOnly,
						});
					}}
				>
					SET_READ_ONLY
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							onlyOne: !options.onlyOne,
						});
					}}
				>
					SET_ONLY_ONE
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						localStorage.setItem("token-test", Date.now().toString());
					}}
				>
					UPDATE_LOCAL_STORAGE_TEST
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							visible: !options.visible,
						});
					}}
				>
					SET_VISIBLE
				</button>
				<button
					className="bg-zinc-300 hover:bg-zinc-400 hover:shadow-md hover:text-zinc-900 active:bg-zinc-500 bg transition-all rounded-md px-3 py-1 font-medium text-zinc-700 shadow-sm"
					onClick={() => {
						setOptions({
							...options,
							//@ts-expect-error
							mapMode: options.mapMode + 1 > 2 ? 0 : options.mapMode + 1,
						});
					}}
				>
					TOGGLE_MAP
				</button>
			</div>
			<div className="flex w-screen mx-4">
				<div className="min-w-[40em]">
					<div className="text-right mb-2">
						<MapMarkerOpenTab></MapMarkerOpenTab>
					</div>
					<MapMarkerFeatureList
						options={{ readOnly: options.readOnly, onlyOne: options.onlyOne, sticky: options.sticky, tag: "sit:locationReference" }}
						onChange={(e) => {}}
						value={options.data.filter((d: any) => !d.detour)}
					></MapMarkerFeatureList>
					<MapMarkerFeatureList
						options={{ readOnly: options.readOnly, onlyOne: options.onlyOne, sticky: options.sticky, tag: "sit:locationReference", detour: true }}
						onChange={(e) => {}}
						value={options.data.filter((d: any) => d.detour)}
					></MapMarkerFeatureList>
				</div>
				<div style={{ display: options.visible ? "block" : "none" }} className="relative h-[42em] w-full m-4">
					{options.mapMode === 0 && (
						<MapMarkerWrapper options={{ readOnly: options.readOnly, objectId: options.id, onlyOne: options.onlyOne, detour: true }}></MapMarkerWrapper>
					)}
					{options.mapMode === 1 && options.visible && <GlobalSettings name={"UVIS"} ids={[]}></GlobalSettings>}
					{options.mapMode === 2 && options.visible && <GlobalSettings></GlobalSettings>}
				</div>
			</div>
		</div>
	);
};
