import { LineStyle, PointStyle } from "../store/Layers.repository";
import { SHIELD_A, SHIELD_P, SHIELD_V } from "./Mapbox-style-helper";

const getComparator = (value: string) => {
	switch (value) {
		case "=":
		case "is":
			return "==";
		case "is_not":
			return "!=";
		case "!=":
		case ">":
		case ">=":
		case "<":
		case "<=":
			return value;
		default:
			return null;
	}
};

const getBooleanOrString = (string: string) => {
	switch (string.toLowerCase()) {
		case "true":
			return true;
		case "false":
			return false;
		default:
			return string;
	}
};

const getFilter = (f: string, defaultValue: any) => {
	if (f === "other") return f;
	let filter = ["all", defaultValue] as any;
	const values = f
		.replaceAll(/is not/g, "is_not")
		.replaceAll(/not in/g, "not_in")
		.split(/\s(?=(?:[^']*'[^']*')*[^']*$)/g);

	let bracket = false;
	let changed = false;

	const getCurrentArray = () => {
		return bracket ? filter[filter.length - 1] : filter;
	};

	for (let i = 0; i < values.length; ) {
		const v1 = values[i]?.toString().replace("(", "");
		const v2 = values[i + 2]?.replaceAll("'", "").replaceAll('"', "")?.toString().replace(")", "");
		let value: (string | number | boolean | string[] | null)[] = [];

		try {
			value = [getComparator(values[i + 1]), ["get", v1], values[i + 2].includes("'") || values[i + 2].includes('"') ? getBooleanOrString(v2.toString()) : Number(v2)];
		} catch (error) {
			console.error("Invalid filter:", f);
		}

		if (value.length === 0) break;

		//check if start of bracket
		if (values[i].includes("(")) {
			filter.push([]);
			bracket = changed = true;
		}

		const arr = getCurrentArray();

		//check main comparator is needed/added otherwise just push'
		if (!changed && values[i - 1] === "or") {
			const last = arr[arr.length - 1];
			if (last?.[0] === "any") {
				last.push(value);
			} else {
				filter.pop();
				filter.push(["any", last, value]);
			}
		} else if (!changed && values[i - 1] === "and") {
			const last = arr[arr.length - 1];
			if (last?.[0] === "all") {
				last.push(value);
			} else {
				filter.pop();
				filter.push(["all", last, value]);
			}
		} else {
			changed = false;
			arr.push(value);
		}

		//check if end of bracket
		if (values[i + 2].includes(")")) {
			bracket = false;
			changed = true;
		}

		i = i + 4;
	}
	return filter;
};

const getLineStyle = (line: LineStyle) => {
	const defaultValue = ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]];
	const filter = line.filter ? getFilter(line.filter, defaultValue) : ["all", defaultValue];
	let styleDef: any = {
		groupName: line.groupName,
		id: line.id.toString(),
		type: "line",
		source: "features",
		"source-layer": line.source ?? "features",
		layout: { "line-join": line.join_style ?? "round", "line-cap": line.cap_style ?? "round" },
	};
	let style = [];
	if (filter) {
		styleDef = { ...styleDef, filter: filter };
	}
	if (line.minZoom && line.maxZoom) {
		styleDef = { ...styleDef, maxzoom: line.maxZoom, minzoom: line.minZoom };
	}

	switch (line.stroke_style) {
		case "contour":
			style = [
				{
					...styleDef,
					id: line.id + "-border",
					paint: {
						"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", line.color],
						"line-width": (line.width || 1) * 3,
						"line-opacity": ["case", ["boolean", ["feature-state", "cluster"], false], 0, line.opacity],
					},
				},
				{
					...styleDef,
					paint: {
						"line-color": "#ffffff",
						"line-width": line.width || 1,
						"line-opacity": ["case", ["boolean", ["feature-state", "cluster"], false], 0, line.opacity],
					},
				},
			];
			break;
		case "dash":
			style = [
				{
					...styleDef,
					paint: {
						"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", line.color],
						"line-width": line.width || 1,
						"line-dasharray": [10, 4],
						"line-opacity": ["case", ["boolean", ["feature-state", "cluster"], false], 0, line.opacity],
					},
				},
			];
			break;
		case "dot":
			style = [
				{
					...styleDef,
					paint: {
						"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", line.color],
						"line-width": line.width || 1,
						"line-dasharray": [0, 2],
						"line-opacity": ["case", ["boolean", ["feature-state", "cluster"], false], 0, line.opacity],
					},
					layout: { ...styleDef.layout, "line-join": "round", "line-cap": "round" },
				},
			];
			break;
		default:
			style = [
				{
					...styleDef,
					id: line.id,
					paint: {
						"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", line.color],
						"line-width": line.width || 1,
						"line-opacity": ["case", ["boolean", ["feature-state", "cluster"], false], 0, line.opacity],
					},
				},
			];
			break;
	}

	switch (line.label_style) {
		case "road_shield":
			const shields = [SHIELD_A(line.label), SHIELD_V(line.label), SHIELD_P(line.label)];
			style.push({ ...styleDef, ...shields[0] });
			style.push({ ...styleDef, ...shields[1] });
			style.push({ ...styleDef, ...shields[2] });
			break;
		case "road_shield_a":
			const shielda = SHIELD_A(line.label);
			style.push({ ...styleDef, ...shielda });
			break;
		case "road_shield_v":
			const shieldv = SHIELD_V(line.label);
			style.push({ ...styleDef, ...shieldv });
			break;
		case "road_shield_p":
			const shieldp = SHIELD_P(line.label);
			style.push({ ...styleDef, ...shieldp });
			break;
		default:
			break;
	}

	return style;
};

const checkOther = (styles: any[], allStyles: any[]) => {
	return styles.map((style) => {
		if (!style.find((s: any) => s.filter === "other")) {
			return style;
		} else {
			const items = styles
				.filter((sty: any) => !sty.find((st: any) => st.filter !== "other"))
				.map((sty: any) => {
					const filters = allStyles
						.flat()
						.filter((st: any) => st.filter !== "other" && st.groupName === sty[0].groupName)
						.map((st: any) => st.filter);
					if (filters.length === 0) return sty;
					const defaults = [filters[0][0], filters[0][1]];
					return sty.map((ss: any) => {
						let newFilters = filters.map((f) => f.slice(2)).flat();
						if (newFilters[0]?.[0] !== "any" && newFilters[0]?.[0] !== "all" && newFilters.length < 1) {
							newFilters = ["!", ...newFilters];
						} else {
							newFilters = ["all", ...newFilters.map((f) => ["!", f])];
						}
						return {
							...ss,
							filter: [...defaults, newFilters],
						};
					});
				});
			return items.flat();
		}
	});
};

export const createMapboxStyle = (lines: LineStyle[], points: PointStyle[], url: string, allLines: LineStyle[], allPoints: PointStyle[], useUvisPopups: boolean) => {
	const lineStyles = checkOther(
		lines.map((l) => getLineStyle(l)),
		allLines.map((l) => getLineStyle(l))
	);
	const pointStyles = checkOther(
		points.map((p) => getPointStyle(p, useUvisPopups)),
		allPoints.map((p) => getPointStyle(p))
	);
	return {
		version: 8,
		name: "custom_style",
		sources: {
			features: {
				tiles: [url],
				type: "vector",
			},
		},
		sprite: "http://localhost:4200/sprites/icons",
		layers: [lineStyles, pointStyles]
			.flat()
			.flat()
			.sort((a, b) => {
				if (isNaN(a.id) && a?.id.includes("highlight")) {
					return 1;
				}
				if (isNaN(b.id) && b?.id.includes("highlight")) {
					return -1;
				}
				return 0;
			}),
	};
};

const getPointStyle = (point: PointStyle, useUvisPopups?: boolean) => {
	const filter = point.filter ? getFilter(point.filter, ["==", ["geometry-type"], "Point"]) : ["all", ["==", ["geometry-type"], "Point"]];
	let styleDef: any = {
		id: point.id.toString(),
		groupName: point.groupName,
		source: "features",
		"source-layer": point.source ?? "features",
		type: "circle",
		paint: {
			"circle-radius": point.piktogram?.split("/")[point.piktogram.split("/").length - 1].split("_")[0] === "c" && point.piktogram ? 28 : point.size,
			"circle-color": point.piktogram ? "#000000" : point.color,
			"circle-opacity": point.piktogram ? ["case", ["boolean", ["feature-state", "cluster"], false], 0, 0.05] : point.opacity,
			"circle-blur": point.piktogram ? 1 : 0
		},
	};
	let style: any = [];
	if (filter) {
		styleDef = { ...styleDef, filter: filter };
	}
	if (point.maxZoom && point.minZoom) {
		styleDef = { ...styleDef, maxzoom: point.maxZoom, minzoom: point.minZoom };
	}
	if (point.piktogram) {
		style = [
			styleDef,
			{
				...styleDef,
				id: point.id + "-point-icon",
				type: "symbol",
				layout: {
					"icon-image": [
						"coalesce",
						["image", ["case", ["boolean", ["feature-state", "cluster"], false], "0", ""]],
						[
							"image",
							["case", ["has", "t1"], ["concat", point.piktogram.split("/").pop()?.replace(".svg", ""), ["to-string", ["round", ["to-number", ["get", "t1"]]]]], ""],
						],
						[
							"image",
							[
								"case",
								["boolean", ["==", ["get", "status"], "PLANNED"], false],
								point.piktogram.split("/").pop()?.replace(".svg", "") + "_planned",
								point.piktogram.split("/").pop()?.replace(".svg", ""),
							],
						],
					],
					"icon-rotation-alignment": "viewport",
					"icon-size": 0.25,
				},
			},
		];
	} else {
		style = [
			{
				...styleDef,
			},
		];
	}

	if (useUvisPopups) {
		style.push({
			...styleDef,
			filter: getFilter(point.filter, ["==", ["geometry-type"], "Polygon"]),
			id: point.id + "-uvis",
			type: "fill",
			paint: {
				"fill-color": point.color,
				"fill-opacity": 0.2,
			},
		});
	}

	return style;
};
