import { Button, Divider, Input, Select } from "antd";
import { Feature, getUid } from "ol";
import { MultiLineString, Point, Polygon, SimpleGeometry } from "ol/geom";
import { MAP, MARKER_LAYER } from "./Map-marker";
import { MapMarkerLinearRef } from "./Map-marker-linear-ref";
import { useContext, useState } from "react";
import { transform } from "ol/proj";
import { createStringXY } from "ol/coordinate.js";
import { FeatureListContext } from "./Map-marker-feature-list";
import { containsXY } from "ol/extent";
import { fromLonLat } from "ol/proj";
import Checkbox1Component from "../shared/UI kit/checkbox-1";

export const MapMarkerFeatureListItem = ({ feature }: { feature: Feature }) => {
	const [customAddress, setCustomAddress] = useState("");
	const { readOnly, detour } = useContext(FeatureListContext);
	const [error, setError] = useState("");
	const [trigger, setTrigger] = useState(false);
	const [twoWay, setTwoWay] = useState(!!feature.get("twoWay"));
	const [turnedAround, setTurnedAround] = useState(!!feature.get("turnedAround"));

	const updateCoords = (e: any) => {
		const geom = feature.getGeometry();
		let [x, y] = e.target.value.split(",");

		if (!x) x = 0;
		if (!y) y = 0;

		const proj = containsXY([189423.14, 169354.52, 766599.76, 449393.56], x, y) ? ["EPSG:3059", "EPSG:3857"] : ["EPSG:4326", "EPSG:3857"];
		if (proj[0] === "EPSG:4326") {
			const latLon = fromLonLat([y, x], "EPSG:3059");
			if (containsXY([189423.14, 169354.52, 766599.76, 449393.56], latLon[0], latLon[1])) {
				const a = x;
				const b = y;
				x = b;
				y = a;
			}
		}

		if (geom instanceof Point) {
			const numbX = Number(x ?? 0);
			const numbY = Number(y ?? 0);

			geom.setCoordinates(transform([numbX > 0 ? numbX : 0, numbY > 0 ? numbY : 0], proj[0], proj[1]).map((v) => (isNaN(v) ? 0 : v)));
		}

		if (geom instanceof MultiLineString) {
			const values = e.target.value.split(",");
			const coords = [];

			for (let i = 0; i < values.length; i = i + 2) {
				const x = values[i];
				const y = values[i + 1];
				coords.push(transform([Number(x), Number(y)], proj[0], proj[1]));
			}

			geom.setCoordinates([coords]);
		}
		feature.setProperties({
			transformedCoords: e.target.value,
			proj: proj,
		});
		MAP.getView()?.fit(feature.getGeometry() as SimpleGeometry, { padding: [120, 120, 120, 120], maxZoom: 12, duration: 500 });
		setTrigger(!trigger);
	};

	const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		e.preventDefault();
		feature.set("addresses", [customAddress, ...(feature.get("addresses") ?? [])]);
	};

	const getCoordinates = () => {
		const geom = feature.getGeometry()!;
		const transformed = feature.get("transformedCoords");
		const proj = feature.get("proj") ?? ["EPSG:3059", "EPSG:3857"];
		const stringify = createStringXY();

		if (transformed) return transformed;

		if (geom instanceof Point && geom.getCoordinates().find((c) => c === 0) === 0) {
			return "";
		}

		if (geom instanceof Point) {
			return stringify(transform(geom.getCoordinates(), proj[1], proj[0]));
		}

		if (geom instanceof MultiLineString || geom instanceof Polygon) {
			return geom
				.getCoordinates()
				.flat()
				.map((c) => transform(c, proj[1], proj[0]))
				.toString();
		}
		return "";
	};

	return (
		<li className="flex px-2 items-center gap-2 py-2">
			<div className="grid grid-cols-3 text-sm gap-2 w-full">
				<MapMarkerLinearRef feature={feature} error={setError} trigger={trigger}></MapMarkerLinearRef>
				<p className="flex items-center justify-end">Koordinātes:</p>
				<Input status={error ? "error" : ""} disabled={readOnly} className="col-span-2" placeholder="Koordinātes" value={getCoordinates()} onChange={updateCoords} />
				{error && <p className="col-start-2 col-span-2 text-[#ff4d4f]">{error}</p>}
				{!detour && (
					<>
						<p className="flex items-center justify-end">Adrese:</p>
						<Select
							disabled={readOnly}
							placeholder="Adrese"
							className="col-span-2"
							onChange={(e) => feature.set("address", e)}
							options={feature.get("addresses")?.map((a: string) => ({ label: a, value: a }))}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider style={{ margin: "8px 0" }} />
									<div className="flex justify-between p-2 gap-2">
										<Input disabled={readOnly} placeholder="Pievienot adresi" onChange={(e) => setCustomAddress(e.target.value)} className="flex-grow" />
										<Button type="text" onClick={addItem}>
											Pievienot
										</Button>
									</div>
								</>
							)}
						/>
					</>
				)}
				{detour && (
					<>
						<p className="flex items-center justify-end">Divvirzienu ceļš:</p>
						<Checkbox1Component
							label={""}
							id={"two_way_detour" + getUid(feature)}
							checked={twoWay}
							onChange={() => {
								setTwoWay(!twoWay);
								feature.set("twoWay", !twoWay);
							}}
							name=""
						></Checkbox1Component>
						<p className="flex items-center justify-end col-start-1 text-end">Apgriezt braukšanas virzienu:</p>
						<Checkbox1Component
							label={""}
							id={"two_way_detour" + getUid(feature)}
							checked={turnedAround}
							onChange={() => {
								setTurnedAround(!turnedAround);
								feature.set("turnedAround", !turnedAround);
							}}
							name=""
						></Checkbox1Component>
					</>
				)}
			</div>
			<button
				disabled={readOnly}
				onClick={() => MARKER_LAYER.getSource()!.removeFeature(feature)}
				className="flex items-center rounded-full transition-all hover:shadow-lg p-1"
			>
				<span className="material-icons">close</span>
			</button>
		</li>
	);
};
