import { LineStyle } from "../../../store/Layers.repository";
import arrow from "../../../shared/img/arrow.png";

export const LegendLineItem = ({ l }: { l: LineStyle }) => {
	switch (l.stroke_style) {
		case "contour":
			return (
				<li className="flex gap-2 items-center">
					<div className="relative h-10 w-10 shrink-0">
						{l.piktogram && <img src={l.piktogram} alt="piktogram" className="h-full w-full z-10" />}

						{
							<div className="absolute inset-0 h-10 w-16 -left-3 gap-1 flex flex-col items-center justify-center -z-10">
								<div
									style={{
										background: l.color,
									}}
									className="w-full h-1.5 rounded-full"
								></div>
								<div
									style={{
										background: l.color,
									}}
									className="w-full h-1.5 rounded-full"
								></div>
							</div>
						}
					</div>
					<p className="ml-4">{l.name}</p>
				</li>
			);
		case "dot":
			return (
				<li className="flex gap-2 items-center">
					<div className="relative h-10 w-10 shrink-0">
						{l.piktogram && <img src={l.piktogram} alt="piktogram" className="h-full w-full z-10" />}
						{
							<div className="absolute inset-0 h-10 w-16 -left-3 -right-3 gap-1 flex items-center justify-center -z-10">
								{[1, 2, 3, 4, 5, 6].map((i) => (
									<div
										key={i}
										style={{
											background: l.color,
										}}
										className="h-2 w-2 rounded-full shrink-0"
									></div>
								))}
							</div>
						}
					</div>
					<p className="ml-4">{l.name}</p>
				</li>
			);
		case "detour":
			return (
				<li className="flex gap-2 items-center">
					<div className="relative h-10 w-10 shrink-0">
						<div className="absolute inset-0 h-10 w-16 -left-3 flex items-center justify-center -z-10">
							<div
								style={{
									background: "#4b4d4f",
								}}
								className="w-full h-2 rounded-full"
							></div>
						</div>
						{
							<div className="absolute inset-0 h-10 w-16 -left-3 -right-3 gap-1 flex items-center justify-center -z-10">
								{[1, 2, 3, 4].map((i) => (
									<img src={arrow} alt="arrow" key={i} className="h-3 w-3 rounded-full shrink-0" />
								))}
							</div>
						}
					</div>
					<p className="ml-4">{l.name}</p>
				</li>
			);
		default:
			return (
				<li className="flex gap-2 items-center">
					<div className="relative h-10 w-10 shrink-0">
						{l.piktogram && <img src={l.piktogram} alt="piktogram" className="h-full w-full z-10" />}

						{
							<div className="absolute inset-0 h-10 w-16 -left-3 flex items-center justify-center -z-10">
								<div
									style={{
										background: l.color,
									}}
									className="w-full h-2 rounded-full"
								></div>
							</div>
						}
					</div>
					<p className="ml-4">{l.name}</p>
				</li>
			);
	}
};
