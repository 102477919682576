import { useEffect, useState } from "react";

const ImageDisplay = ({ hexData }: { hexData: string }) => {
	const [imageUrl, setImageUrl] = useState(null);

	useEffect(() => {
		if (!hexData) {
			setImageUrl(null);
			return;
		}

		let input = hexData.replace(/[^A-Fa-f0-9]/g, "");
		if (input.length % 2) {
			console.log("cleaned hex string length is odd.");
			return;
		}

		let binary = [] as any;
		for (let i = 0; i < input.length / 2; i++) {
			let h = input.substr(i * 2, 2);
			binary[i] = parseInt(h, 16);
		}

		let byteArray = new Uint8Array(binary);

		const url = window.URL.createObjectURL(new Blob([byteArray], { type: "application/octet-stream" })) as any;

		setImageUrl(url);

		return () => URL.revokeObjectURL(url);
	}, [hexData]);

	return <div>{imageUrl ? <img src={imageUrl} alt="JPEG" /> : <></>}</div>;
};

export default ImageDisplay;
