import axios from "axios";
import { useEffect, useState } from "react";
import { ENTRYPOINT } from "../Endpoints";
import { Bubble } from "./Bubble";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../Map.repository";
import { useSearchParams } from "react-router-dom";
import LayerGroup from "ol/layer/Group";
import { Layer } from "ol/layer";
import { EventsKey } from "ol/events";
import { filter, take } from "rxjs";
import { Map, Overlay } from "ol";

export interface BubbleResponse {
	name: string;
	center: string;
	stats: Stat[];
}

export interface Stat {
	name: string;
	count: number;
	icon: string;
}

let initial: string;
let eventsKey: EventsKey;
export let BUBBLE_BASE_LAYER: Layer;
export let BUBBLE_OVERLAYS: Overlay[] = [];

export const addListenerRemoveBubbleOverlays = (map: Map) => {
	const key = map.on("movestart", () => {
		if (BUBBLE_OVERLAYS.length > 0) {
			BUBBLE_OVERLAYS.forEach((o) => map.removeOverlay(o));
			map.un("movestart", key.listener);
		}
		const lg = map
			.getLayers()
			.getArray()
			.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
		lg?.getLayersArray().forEach((l) => l.setZIndex(-1000));
	});
};

export const BubbleWrapper = () => {
	const [items, setItems] = useState<BubbleResponse[]>([]);
	const [props] = useObservable(getMapStore());
	const [params] = useSearchParams();
	if (initial === undefined) initial = params.get("layers") ?? "";

	useEffect(() => {
		axios.get<BubbleResponse[]>(`${ENTRYPOINT}/overview/`).then((res) => {
			setItems(res.data);
			getMapStore()
				.pipe(
					filter((mapStore) => !!mapStore.map),
					take(1)
				)
				.subscribe((mapStore) => {
					const map = mapStore.map!;
					if (initial === "") addListenerRemoveBubbleOverlays(map);
				});
		});
	}, []);

	useEffect(() => {
		const map = props.map;

		if (!map) return;

		if (initial === "") {
			eventsKey = map.getLayerGroup().on("change", () => {
				const lg = map
					.getLayers()
					.getArray()
					.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
				if (lg) {
					lg?.getLayersArray().forEach((l) => l.setZIndex(100));
					map.getLayerGroup().un("change", eventsKey.listener);
				}
			});
		}
	}, [props.map]);

	return <>{props.map && items.map((i) => <Bubble add={initial === ""} key={i.center} map={props.map!} bubble={i}></Bubble>)}</>;
};
