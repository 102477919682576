import axios from "axios";
import i18next from "i18next";
import { useEffect } from "react";

export function getLang() {
	const url = window.location.href;
	if (url.includes("lang=en")) {
		return "en";
	}
	if (url.includes("lang=lv")) {
		return "lv";
	}
	return "lv";
}

export const LangHeader = () => {
	useEffect(() => {
		const lang = getLang();
		if (!!lang)
			axios.interceptors.request.use(
				(config) => {
					if (i18next.resolvedLanguage !== lang) i18next.changeLanguage(lang);
					config.headers["Accept-Language"] = lang;
					return config;
				},
				(error) => {
					Promise.reject(error);
				}
			);
	}, []);

	return <></>;
};
