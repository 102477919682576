import { useEffect, useState } from "react";
import { CLUSTER } from "../data-layers/layer-switcher/Cluster/Cooler-cluster";
import { Map } from "ol";

let ZOOM_PRESETS = [
	[96, 0],
	[96, 0],
	[96, 0],
	[96, 0],
	[96, 0],
	[96, 0],
	[112, 0],
	[112, 96],
	[128, 96],
	[128, 96],
	[178, 48],
	[178, 48],
	[200, 48],
	[200, 48],
	[200, 48],
	[178, 48],
	[178, 48],
	[128, 48],
	[128, 48],
	[128, 48],
];

export const ClusterSliders = ({ map }: { map: Map }) => {
	const view = map.getView();

	const [distance, setDistance] = useState(ZOOM_PRESETS[20 - Math.round(view.getZoom()!)][0]);
	const [minDistance, setMinDistance] = useState(ZOOM_PRESETS[20 - Math.round(view.getZoom()!)][1]);

	useEffect(() => {
		const source = CLUSTER.getSource();
		source.setDistance(distance);
		source.setMinDistance(minDistance);
	}, [distance, minDistance]);

	useEffect(() => {
		view.on("change:resolution", () => {
			const preset = ZOOM_PRESETS[20 - Math.round(view.getZoom()!)];
			setDistance(preset[0]);
			setMinDistance(preset[1]);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return localStorage.getItem("clusterSlider") ? (
		<div className="absolute inset-x-0 bottom-0 p-8 flex items-center justify-center pointer-events-none">
			<div className="rounded-md pl-8 pr-6 pt-4 pb-2 bg-slate-200/50 text-gray-800 backdrop-filter backdrop-blur-md font-bold text-sm shadow-md flex items-center gap-6 pointer-events-auto">
				<div>
					<p>DISTANCE BETWEEN FEATURES: {distance}</p>
					<input
						type="range"
						min="0"
						max={200}
						value={distance}
						className="w-full"
						onChange={(e) => {
							setDistance(Number(e.target.value));
							if (distance < minDistance) setMinDistance(distance);
						}}
					/>
					<p>MIN-DISTANCE BETWEEN CLUSTERS: {minDistance}</p>
					<input type="range" min="0" max={distance} value={minDistance} className="w-full" onChange={(e) => setMinDistance(Number(e.target.value))} />
				</div>
				<div>
					<button
						onClick={() => (ZOOM_PRESETS[Math.round(20 - map.getView().getZoom()!)] = [distance, minDistance])}
						className="p-2 bg-slate-500 rounded-md text-white shadow-md active:bg-slate-900 transition-colors"
					>
						SAVE
					</button>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};
