import Feature, { FeatureLike } from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { LineString } from "ol/geom";
import along from "@turf/along";
import length from "@turf/length";
import { Icon, Stroke, Style } from "ol/style";
import { LineStyle } from "../store/Layers.repository";

const getLineStringCoordsFromFlatCoordinates = (flatCoordinates: number[]) => {
	return flatCoordinates.reduce((a, b) => {
		if (a.length === 0) {
			return [b];
		} else if ((a[a.length - 1] as number[]).length === 2) {
			return [...a, b] as any;
		} else {
			const c = [a.pop()];
			return [...a, [...c, b]];
		}
	}, []);
};

export const iconInMiddleOfLineString = (feature: Feature<LineString>, icon: string) => {
	//@ts-expect-error Access protected property
	const coords = getLineStringCoordsFromFlatCoordinates(feature.getGeometry()!.flatCoordinates as number[]);
	const lineString = new LineString(coords);
	const reader = new GeoJSON();
	const geo = reader.writeGeometryObject(lineString, {
		dataProjection: "EPSG:4326",
		featureProjection: "EPSG:3857",
	});
	const geoPoint = along(geo as any, length(geo as any) / 2);
	return new Style({
		image: new Icon({
			src: icon,
		}),
		geometry: reader
			.readFeature(geoPoint, {
				dataProjection: "EPSG:4326",
				featureProjection: "EPSG:3857",
			})
			//@ts-expect-error
			.getGeometry()!,
	});
};

export const addEndsToLineString = (feature: FeatureLike, lineStyle: LineStyle, endSize?: number) => {
	const coords = getLineStringCoordsFromFlatCoordinates((feature as any).getGeometry().flatCoordinates as number[]);

	const lineString = new LineString(coords);
	const size = endSize ?? lineString.getLength() / 60;
	// start
	let dx = coords[1][0] - coords[0][0];
	let dy = coords[1][1] - coords[0][1];
	let rotation = Math.atan2(dy, dx);
	let startLine = new LineString([
		[coords[0][0], coords[0][1] - size],
		[coords[0][0], coords[0][1] + size],
	]);
	startLine.rotate(rotation, coords[0]);
	// end
	const lastIndex = coords.length - 1;
	dx = coords[lastIndex - 1][0] - coords[lastIndex][0];
	dy = coords[lastIndex - 1][1] - coords[lastIndex][1];
	rotation = Math.atan2(dy, dx);
	let endLine = new LineString([
		[coords[lastIndex][0], coords[lastIndex][1] - size],
		[coords[lastIndex][0], coords[lastIndex][1] + size],
	]);
	endLine.rotate(rotation, coords[lastIndex]);

	return [startLine, endLine].map(
		(line) =>
			new Style({
				geometry: line,
				stroke: new Stroke({
					color: lineStyle.color,
					width: lineStyle.width,
				}),
			})
	);
};
