/* eslint-disable @typescript-eslint/no-unused-vars */
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../map/Map.repository";
import { useEffect } from "react";
import LayerGroup from "ol/layer/Group";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { AltLayerEntity, GroupEntity, LayerStyle } from "../store/Layers.repository";
import { dataLayerStyle, doDarkMagic, strToFilter } from "./Style-generator";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster.js";
import { Feature, Map, VectorTile } from "ol";
import { Point } from "ol/geom";
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorImageLayer from "ol/layer/VectorImage";
import { IS_LOCALHOST, LAYERPOINT } from "../map/Endpoints";
import { Layer } from "ol/layer";
import { take } from "rxjs";

export let LAYERGROUPS: LayerGroup[] = []; //List of LayerGroups for Data-layer switcher

const DISTANCE = 96;

export const getLayerID = (url: string) => {
	const id = url.split("/");
	return `${id[id.length - 6]}_${id[id.length - 5]}`;
};

export const LAYER_CACHE: { [id: string]: Feature[] } = {};

export const CLUSTER_SOURCE = new VectorSource();

const PRESETS = [
	[{ x: 0, y: 0 }],
	[
		{ x: -24, y: 0 },
		{ x: 24, y: 0 },
	],
	[
		{ x: 0, y: 24 },
		{ x: -24, y: -24 },
		{ x: 24, y: -24 },
	],
	[
		{ x: -24, y: 24 },
		{ x: 24, y: 24 },
		{ x: -24, y: -24 },
		{ x: 24, y: -24 },
	],
];

export const createClusterSource = ({ item, id, refresh }: { item: AltLayerEntity; id: string; refresh?: boolean }) => {
	const source = new VectorTileSource({
		transition: 0,
		url: IS_LOCALHOST
			? `${LAYERPOINT}/layer/${item.table.replace("_", "/")}/tile/{z}/{x}/{y}${item.data_api_filter ? "?filter=" + item.data_api_filter : ""}`
			: item.mvt_url,
		projection: "EPSG:3857",
		format: new MVT({
			featureClass: Feature as any,
		}),
		attributions: item.attributions,
		wrapX: false,
		cacheSize: 16,
		overlaps: false,
	});
	source.set("refresh", !!refresh);
	source.on("tileloadend", (e) => {
		getMapStore()
			.pipe(take(1))
			.subscribe((mapStore) => {
				const map = mapStore.map!;
				const zoom = map.getView().getZoom()!;
				const features = (e.tile as any).getFeatures().filter((f: any) => {
					if (
						f.getGeometry()?.getType() === "Point" &&
						item.table !== "kafkamessages_uvis" &&
						item.points.find((p) => !p.filter || doDarkMagic(f, { filter: strToFilter(p.filter), style: true as any, styleDef: undefined as any })) //override typedefs to reuse code
					) {
						(f as Feature).set("id", id + "-" + f.get("id"), true);
						return true;
					}
					return false;
				}) as Feature[];
				const newFeatures = features.filter((f) => {
					const ids = LAYER_CACHE[id]?.map((feature: Feature) => feature.get("id")) ?? [];

					if (f.getGeometry() instanceof Point) {
						if (ids.indexOf(f.get("id")) === -1) {
							f.set("zoom", zoom, true);
							return true;
						}

						const cacheFeature = LAYER_CACHE[id][ids.indexOf(f.get("id"))];

						if (source.get("refresh") && !cacheFeature?.get("justUpdated")) {
							CLUSTER_SOURCE.removeFeature(cacheFeature);
							LAYER_CACHE[id].splice(ids.indexOf(f.get("id")), 1);
							f.set("zoom", zoom, true);
							f.setProperties({ zoom: zoom, justUpdated: true });
							return true;
						} else {
							cacheFeature.set("justUpdated", false);
						}

						if (zoom > cacheFeature?.get("zoom")) {
							cacheFeature.set("zoom", zoom, true);
							const geom = cacheFeature.getGeometry() as Point;
							//@ts-expect-error override flatCoordinates
							geom.flatCoordinates = (f.getGeometry() as Point).getFlatCoordinates();
						}
						return false;
					}
					return false;
				});
				CLUSTER_SOURCE.addFeatures(newFeatures);
				setTimeout(() => source.set("refresh", false), 150);

				if (newFeatures.length > 0) {
					LAYER_CACHE[id] = [...newFeatures, ...(LAYER_CACHE[id] ?? [])];
				}
			});
	});
	source.setProperties({ params: { item, id } });
	return source;
};

export const addClusterGroup = (group: GroupEntity, map: Map, layerList?: string[]) => {
	let layerGroupVisible = !!layerList?.find((i) => i === group.id);
	const layerGroup = new LayerGroup({
		zIndex: 1000,
		visible: layerList ? layerList.includes(group.name + "-" + group.id) : !!(group.layers as any).find((l: AltLayerEntity) => l.enabled),
		properties: {
			name: group.name,
			id: group.name + "-" + group.id,
			type: "cluster",
			attributions: group.attributions,
			dataSource: group.dataSource,
		},
		layers: (group.layers as any)
			.filter((l: AltLayerEntity) => l.clustered && l.table !== "kafkamessages_uvis")
			.map((l: AltLayerEntity, i: number) => {
				const id = l.name + "-" + l.id;
				const visible = layerList === undefined ? l.enabled : !!layerList?.find((i) => i === id);
				if (layerList === undefined && visible) layerGroupVisible = true;
				const layer = new VectorTileLayer({
					opacity: (100 - l.opacity) / 100,
					zIndex: l.table.includes("uvis") ? -2 : 1,
					renderMode: "vector",
					renderOrder: null as unknown as any, //Wrong typedef workaround, null disables ordering
					declutter: true,
					renderBuffer: 0,
					minZoom: l.zoom_from - 0.1,
					maxZoom: l.zoom_to,
					properties: {
						name: l.name,
						id,
						table: l.table,
						legend: {
							points: l.points,
							lines: l.lines,
						},
						attributions: l.attributions,
						clustered: true,
					},
					source: createClusterSource({ item: l, id: id }),
					visible: layerList ? layerList.includes(l.name + "-" + l.id) : l.enabled,
				});

				const style = dataLayerStyle({
					lines: l.lines,
					points: l.points,
					params: {
						attributes: l.attributes,
						title: l.label,
						titleFont: l.label_style,
						table: l.table,
						layer,
						use_uvis_popups: group.use_uvis_popups,
						layerName: l.name,
					},
				});

				layer.setProperties({ style: style });
				layer.setStyle(style);

				layer.on("change:visible", (e) => {
					layer.setOpacity(1);
					const cache = LAYER_CACHE[layer.get("id")];
					if (e.oldValue && cache) {
						removeFeatures(cache);
					} else if (layerGroup.getVisible() && cache) {
						CLUSTER_SOURCE.addFeatures(cache);
					}
				});

				map.getView().on("change", () => {
					const zoom = map.getView().getZoom()!;
					const cache = LAYER_CACHE[layer.get("id")];
					if (layer.getVisible() && layerGroup.getVisible() && (zoom < l.zoom_from || zoom > l.zoom_to) && cache) {
						removeFeatures(cache);
						layer.set("range", false, true);
					} else if (layer.getVisible() && layerGroup.getVisible() && layer.get("range") === false && cache) {
						CLUSTER_SOURCE.addFeatures(cache);
						layer.set("range", true, true);
					}
				});

				return layer;
			}),
	});

	layerGroup.on("change:visible", (e) => {
		const layers = layerGroup.getLayersArray().filter((l: Layer) => l.getVisible());
		if (layers.length > 0) {
			layers.forEach((l: Layer) => {
				const cache = LAYER_CACHE[l.get("id")];
				if (e.oldValue && cache) {
					removeFeatures(cache);
				} else if (cache) {
					CLUSTER_SOURCE.addFeatures(cache);
				}
			});
		}
	});
	map.addLayer(layerGroup);
	return layerGroup;
};

const removeFeatures = (cache: Feature[]) => {
	const features = CLUSTER_SOURCE.getFeatures().filter((f) => !cache.find((c) => f.get("id") === c.get("id")));
	CLUSTER_SOURCE.clear();
	CLUSTER_SOURCE.addFeatures(features);
};

export default function DataLayersCluster() {
	const [mapStore] = useObservable(getMapStore());

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		const cluster = new VectorImageLayer({
			zIndex: 2,
			maxZoom: 30,
			properties: {
				name: "Cluster",
				id: "cluster",
			},
			source: new Cluster({
				distance: DISTANCE,
				source: CLUSTER_SOURCE,
				geometryFunction: (feature) => {
					const geom = feature.getGeometry()!;
					if (geom instanceof Point) {
						return geom;
					}
					return null;
				},
				createCluster: (point, features) => {
					if (features.length === 1) {
						return features[0];
					}
					return new Feature({
						geometry: point,
						features: features,
					});
				},
			}),
			//@ts-expect-error
			style: (feature: Feature) => {
				const props = feature.getProperties();

				//If not a cluster faeture, return feature style
				if (props.features === undefined && props.internal) {
					return props.internal.style;
				}

				const features = (props.features?.filter((f: Feature) => f.get("internal")?.["style"]) as Feature[]) ?? [];
				const icons: { [key: string]: Feature[] } = {};

				features.forEach((f) => {
					const internal = f.get("internal");
					icons[internal["icon"]] = [...(icons[internal["icon"]] ?? []), f];
				});

				if (features.length > 0) {
					const style: Style[] = [];
					const keys = Object.keys(icons);
					keys.forEach((key, i) => {
						const preset = PRESETS[keys.length - 1]?.[i];
						const floor = Math.floor(i / 3);
						const y = preset?.y ?? 48 - floor * 48; // Y displacement
						const x = preset?.x ?? -48 + (i % 3) * 48; // X displacement
						const feats = icons[key];
						if (feats.length > 1)
							style.push(
								new Style({
									zIndex: Infinity,
									image: new CircleStyle({
										displacement: [x + 16, y + 16],
										radius: 12,
										stroke: new Stroke({
											color: "#fff",
											width: 2,
										}),
										fill: new Fill({
											color: "#e51335",
										}),
									}),
									text: new Text({
										offsetX: x + 16,
										offsetY: -y - 16,
										text: feats.length.toString(),
										font: "bold 12px sans-serif",
										justify: "center",
										fill: new Fill({
											color: "#fff",
										}),
									}),
								})
							);
						const internal = feats[0].get("internal");
						const fStyle = internal?.["style"].map((s: Style) => {
							const style = s.clone();
							style.getImage()?.setDisplacement([x, y]);
							if (internal["table"] === "kafkamessages_cms") {
								style.getText()!.setOffsetX(x);
								style.getText()!.setOffsetY(-y);
							}
							return style;
						}) as Style[];
						style.push(...fStyle);
					});
					return style;
				}

				if (features?.length > 1) {
					return [
						new Style({
							zIndex: Infinity,
							image: new CircleStyle({
								displacement: [16, -16],
								radius: 16,
								stroke: new Stroke({
									color: "#fff",
									width: 4,
								}),
								fill: new Fill({
									color: "#e51335",
								}),
							}),
							text: new Text({
								offsetX: 16,
								offsetY: 17,
								text: features.length.toString(),
								font: "bold 14px sans-serif",
								justify: "center",
								fill: new Fill({
									color: "#fff",
								}),
							}),
						}),
					];
				} else {
					return features && features.length > 0 ? features[0].get("internal")?.["style"] : undefined;
				}
			},
		});

		map.getView().on("change:resolution", () => {
			if (map.getView().getZoom()! > 21) {
				(cluster.getSource() as any).setDistance(0);
			} else {
				(cluster.getSource() as any).setDistance(DISTANCE);
			}
		});

		map.addLayer(cluster);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapStore]);

	return <></>;
}
