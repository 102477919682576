import { useObservable } from "@ngneat/use-observable";
import { AltLayerEntity, LayerStyle, LineStyle, PointStyle, getGroupEntities } from "../store/Layers.repository";
import { map } from "rxjs";
import { getMapStore } from "../map/Map.repository";
import { useContext, useEffect, useState } from "react";
import LayerGroup from "ol/layer/Group";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { createMapboxStyle } from "./Mapbox-style-generator";
import { stylefunction } from "ol-mapbox-style";
import spriteJson from "../shared/sprites/icons.json";
import iconspng from "../shared/sprites/icons.png";
import { useSearchParams } from "react-router-dom";
import { StyleLayerProp, clearStyleItems, updateStyleItems } from "./Style-layer.repository";
import { getMVTStyles } from "./Mapbox-helper";
import { Feature, VectorTile } from "ol";
import { DataLayersExtentZoom } from "./Data-layers-extent-zoom";
import { addCoolerClusterGroup } from "./layer-switcher/Cluster/Cooler-cluster-group";
import { GlobalSettingsContext } from "../Settings";
import { Icon, Stroke, Style } from "ol/style";
import { LineString, MultiLineString, Point } from "ol/geom";
import arrow from "../shared/img/arrow.png";
import arrowDouble from "../shared/img/arrow_double_sided.png";
import { checkIfVisible } from "./layer-switcher/Cluster/Cooler-cluster-style";
import { getStyle } from "../map/overlay/Overlay";
import { doDarkMagic, strToFilter } from "./Style-generator";

export interface TableItem {
	table: string;
	originalTable: string | undefined;
	layers: LayerStyle[];
	use_uvis_popups: boolean;
	merged_tiles: string | null;
	url: string;
}

let MERGED_TILE_URL_LIST: string[] = [];
export let TABLE_LIST: TableItem[] = [];

export function getLayerListFromURL() {
	return window.location.hash
		.split("&")[1]
		?.split("=")[1]
		?.split(".")
		.map((s) => decodeURI(s));
}

export let DETOUR_LIST: { [key: string]: Feature } = {};

export const createSource = ({ url, item, lines, points }: { url: string; lines: LineStyle[]; points: PointStyle[]; item: any }) => {
	const source = new VectorTileSource({
		attributions: Array.from(new Set(item.layers.map((l: any) => l.attributions).flat())),
		transition: 0,
		url,
		projection: "EPSG:3857",
		format: new MVT({ featureClass: Feature as any }),
		wrapX: false,
		overlaps: false,
		tileLoadFunction: (tile, url) => {
			const t = tile as VectorTile<Feature>;
			t.setLoader(function (extent, resolution, projection) {
				fetch(url).then(function (response) {
					response.arrayBuffer().then(function (data) {
						const format = t.getFormat();
						let features = format.readFeatures(data, {
							extent: extent,
							featureProjection: projection,
						}) as Feature[];
						features = features.map((f) => {
							const props = f.getProperties();
							(f as any).id_ = (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"];
							f.setProperties(
								{
									...(f as any).properties_,
									internal: {
										table: item.table,
										lines: lines,
										points: points,
										use_uvis_popups: item.use_uvis_popups,
									},
								},
								true
							);

							if (f.get("layer").includes("detour")) {
								if (!DETOUR_LIST[f.get("kafka_id")]) DETOUR_LIST[f.get("kafka_id")] = f;

								const segments: Style[] = [];
								let lines: LineString[] = [];
								if (f.getGeometry()?.getType() === "LineString") {
									lines.push(f.getGeometry() as LineString);
								} else if (f.getGeometry()?.getType() === "MultiLineString") {
									lines = (f as Feature<MultiLineString>).getGeometry()?.getLineStrings()!;
								}

								lines.forEach((l) => {
									l.forEachSegment((start, end) => {
										const line = new LineString([start, end]);
										const len = line.getLength() / resolution;
										let curr = 0.2;
										let total = len / 40;

										const dx = end[0] - start[0];
										const dy = end[1] - start[1];
										const rotation = Math.atan2(f.get("turn_around") ? -dy : dy, f.get("turn_around") ? -dx : dx);

										while (curr < total) {
											segments.push(
												new Style({
													geometry: new Point(line.getCoordinateAt(curr / total)),
													image: new Icon({
														src: f.get("is_two_way") ? arrowDouble : arrow,
														anchor: [0.75, 0.5],
														rotateWithView: true,
														scale: 0.7,
														rotation: -rotation,
													}),
												})
											);
											curr = curr + 1;
										}
									});
								});

								f.setStyle((feature, a) => {
									const detour = DETOUR_LIST[feature.get("kafka_id")];
									if (detour) {
										const properties = { ...detour.getProperties() };
										delete properties.geometry;
										delete properties.layer;
										f.setProperties(
											{
												...properties,
											},
											true
										);
										if (!detour?.get("visible") || !checkIfVisible(feature.get("internal")?.["layerName"])) {
											return undefined;
										}
									}

									segments.forEach((s) => s.getImage()?.setScale(a > 200 ? 0.5 : 0.65));

									return [
										new Style({
											stroke: new Stroke({
												color: "#4b4d4f",
												width: a > 200 ? 10 : 14,
											}),
										}),
										...segments,
									];
								});
							}

							if (f.get("layer").includes("kafkamessages_plannedevent") || f.get("layer").includes("kafkamessages_notplannedevent")) {
								const detour = DETOUR_LIST[f.get("kafka_id")];
								const style = getStyle(f, f.get("internal"));

								if (detour && !detour.get("updated") && style && doDarkMagic(f, { ...style, filter: strToFilter(style.filter), style: true })) {
									const properties = { ...f.getProperties() };
									delete properties.geometry;
									delete properties.layer;
									detour.setProperties(
										{
											linear_reference: properties.linear_reference,
											internal: {
												...properties.internal,
												layerName: style?.layerName,
												attributes: ["detour"],
												groupName: style?.groupName,
												pointStyles: [style],
												icon: style?.icon,
												styleName: style?.name,
												style: [style],
											},
											visible: !!style,
											updated: !!style,
										},
										true
									);
								}
							}
							return f;
						});
						t.setFeatures(features as any);
					});
				});
			});
		},
	});
	source.setProperties({ params: { url, item, lines, points } });
	return source;
};

export default function DataLayersNewLayerManager() {
	const [groups] = useObservable(getGroupEntities().pipe(map((groups) => groups.filter((g) => g.group === "data"))));
	const [mapStore] = useObservable(getMapStore());
	const [searchParams] = useSearchParams();
	const { predefined_layers } = useContext(GlobalSettingsContext);

	const [loaded, setLoaded] = useState(false);
	const [enabled, setEnableExtent] = useState(false);

	useEffect(() => {
		if (!mapStore.map || loaded) return;
		setLoaded(true);

		const layerList = searchParams.get("layers")?.split(",");
		const tableList: TableItem[] = [];
		const styleItems: StyleLayerProp[] = [];

		groups
			.flatMap((g) => {
				const layers = g.layers as any;
				styleItems.push({
					id: g.name + "-" + g.id,
					name: g.name,
					layers: layers.map((l: AltLayerEntity, i: number) => {
						if (l.merged_tiles) {
							if (!MERGED_TILE_URL_LIST.includes(l.merged_tiles)) {
								MERGED_TILE_URL_LIST.push(l.merged_tiles);
							}
							l.original_table = l.table;
							l.table = "merged_tiles_" + MERGED_TILE_URL_LIST.indexOf(l.merged_tiles);
						}
						if (predefined_layers?.mainGroup === Number(g.id.replace("data-", ""))) {
							const filter = predefined_layers.features.map((kafkaid) => `kafka_id = '${kafkaid}'`);
							l.points = l.points.map((p) => ({ ...p, filter: filter.join(" or ") }));
							l.lines = l.lines.map((ls) => ({ ...ls, filter: filter.join(" or ") }));
						}
						const [lines, points] = getMVTStyles([l], !!l.merged_tiles);

						layers[i].points = points; //For clusters
						return {
							name: l.name,
							id: l.name + "-" + l.id,
							visible: predefined_layers
								? predefined_layers.groups.flatMap((g) => g.layerId).includes(l.id)
								: layerList
								? layerList.includes(l.name + "-" + l.id)
								: l.enabled,
							table: l.table,
							originalTable: l.original_table,
							lines: lines,
							points: points,
							clustered: l.clustered,
							attributions: l.attributions,
							index: i,
							mvt: true,
						};
					}),
					visible: predefined_layers
						? predefined_layers.groups.map((g) => g.groupId).includes(Number(g.id.replace("data-", "")))
						: layerList
						? layerList.includes(g.name + "-" + g.id)
						: !!layers.find((l: AltLayerEntity) => l.enabled),
					dataSource: g.dataSource,
				});

				return layers.map((l: AltLayerEntity, i: number) => ({ ...l, use_uvis_popups: g.use_uvis_popups, index: i }));
			})
			.forEach((l) => {
				const layer = l as LayerStyle;

				const item = tableList.find((item) => item.table === layer.table);

				if (item) {
					item.layers.push(layer);
					if (layer.use_uvis_popups) item.use_uvis_popups = true;
				} else {
					tableList.push({
						table: layer.table,
						layers: [layer],
						use_uvis_popups: !!layer.use_uvis_popups,
						merged_tiles: layer.merged_tiles,
						url: layer.mvt_url,
						originalTable: l.original_table,
					});
				}
			});

		addCoolerClusterGroup(
			tableList
				.map((item) => {
					const layers = item.layers.filter((l) => l.clustered);
					return { ...item, layers };
				})
				.filter((item) => item.layers.length !== 0),
			mapStore.map,
			styleItems
		);

		const dataLayerGroup = new LayerGroup({
			properties: {
				type: "data",
			},
			layers: tableList.reverse().map((item) => {
				const url = item.merged_tiles ?? item.url;
				const names = styleItems.map((sl) => sl.name);

				const styleLayers = item.layers
					.filter((l) => styleItems.find((i) => i.visible && i.layers.find((il) => il.id === l.name + "-" + l.id)?.visible))
					.map((l) => ({ ...l, points: l.clustered ? l.points.map((p) => ({ ...p, clustered: true })) : l.points }))
					.sort((a, b) => {
						const g1 = styleItems.find((sl) => !!sl.layers.find((layer) => layer.name === a.name))!.name;
						const g2 = styleItems.find((sl) => !!sl.layers.find((layer) => layer.name === b.name))!.name;
						return names.indexOf(g2) === names.indexOf(g1) ? (a.index > b.index ? -1 : 1) : names.indexOf(g2) - names.indexOf(g1);
					});
				const [lines, points] = getMVTStyles(styleLayers, !!item.merged_tiles);

				const visible = lines.length > 0 || points.length > 0;
				const [internalLines, internalPoints] = getMVTStyles(item.layers, !!item.merged_tiles);
				const [allLines, allPoints] = getMVTStyles(item.layers);

				const layer = new VectorTileLayer({
					zIndex: -1,
					renderOrder: null as unknown as any, //Wrong typedef workaround, null disables ordering
					declutter: false,
					renderBuffer: allPoints.length > 0 ? 48 : 0,
					className: "",
					properties: {
						id: item.table,
						url,
						table: item.table,
						clustered: false,
						use_uvis_popups: item.use_uvis_popups,
						layers: item.layers,
						type: "data",
					},
					source: createSource({ url: url, lines: internalLines, points: internalPoints, item }),
					visible: visible,
				});

				if (visible) {
					const mapboxStyle = createMapboxStyle(lines, points, url, allLines, allPoints, item.use_uvis_popups);
					console.log("mapboxStyle:", mapboxStyle);
					stylefunction(layer, mapboxStyle, "features", undefined, spriteJson, iconspng);
				}

				return layer;
			}),
		});

		updateStyleItems(styleItems);
		TABLE_LIST = tableList;

		mapStore.map.addLayer(dataLayerGroup);
		mapStore.map.changed();
		setEnableExtent(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, groups, mapStore, loaded]);

	useEffect(() => {
		return () => {
			clearStyleItems();
		};
	}, []);

	return enabled ? <DataLayersExtentZoom /> : <></>;
}
