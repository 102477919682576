import { useContext } from "react";
import { GlobalSettingsContext } from "../Settings";
import { DatabaseSVG } from "../shared/svg/database-svg";
import LogoSVG from "./Logo";
import { BUBBLE_OVERLAYS, addListenerRemoveBubbleOverlays } from "../map/bubble/Bubble-wrapper";
import { getMapStore } from "../map/Map.repository";
import { useObservable } from "@ngneat/use-observable";
import { isMobile } from "react-device-detect";
import { MAP_CENTER } from "../map/Map";
import LayerGroup from "ol/layer/Group";
import { useTranslation } from "react-i18next";

export const LogoBtn = ({ overviewOpened }: { overviewOpened: () => void }) => {
	const [props] = useObservable(getMapStore());
	const { system_code } = useContext(GlobalSettingsContext);
	const [t] = useTranslation();

	const map = props.map;

	const openOverview = () => {
		if (system_code !== "NPP" || !map) return;

		const v = map.getView();
		v.setZoom(isMobile ? 5 : 8);
		v.setCenter(MAP_CENTER);

		BUBBLE_OVERLAYS?.forEach((overlay) => {
			map.addOverlay(overlay);
		});

		const lg = map
			.getLayers()
			.getArray()
			.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
		lg?.getLayersArray().forEach((l) => l.setZIndex(100));

		setTimeout(() => addListenerRemoveBubbleOverlays(map), 150);
	};

	return (
		<div
			onClick={() => {
				openOverview();
				overviewOpened();
			}}
			title={system_code === "NPP" ? t("returnToDefaultView") + "" : ""}
			className="block cursor-pointer"
		>
			{system_code === "SIPR" ? <DatabaseSVG className="mx-1 h-10 w-9"></DatabaseSVG> : <LogoSVG alt="logo" className="mx-2 h-10 w-7 -my-1"></LogoSVG>}
		</div>
	);
};
