import { Map, VectorRenderTile } from "ol";
import { getGroupEntities } from "../store/Layers.repository";

export class CustomMap extends Map {
	private func = new Map().getTilePriority;
	private URL_LIST: string[] = [];

	getTilePriority(tile: VectorRenderTile, tileSourceKey: any, tileCenter: any, tileResolution: any): any {
		if (this.URL_LIST.length === 0)
			getGroupEntities().subscribe((groups) => (this.URL_LIST = groups.find((g) => g.group === "base")?.layers.map((l) => (l as any).url) ?? []));

		if (this.URL_LIST.includes(tile.key)) {
			return 1;
		}

		return this.func(tile, tileSourceKey, tileCenter, tileResolution);
	}
}
