import { Feature, Map } from "ol";
import { setFeatureState } from "ol-mapbox-style";
import RenderFeature from "ol/render/Feature";
import { useEffect, useRef } from "react";
import { COOLER_CLUSTER_SOURCE } from "../data-layers/layer-switcher/Cluster/Cooler-cluster";
import { Style } from "ol/style";
import { getPointHighlight } from "./Highlight-style";
import { getPointShadow } from "../data-layers/Style-generator";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { findStyle } from "../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { Point } from "ol/geom";
import { getStyle } from "./overlay/Overlay";

export const HIGHLIGHT_LAYER = new VectorLayer({
	properties: { name: "Highlight", id: "highlight" },
	source: new VectorSource(),
});

export const FeatureHighlighter = ({ map }: { map: Map }) => {
	const features = useRef<(Feature | RenderFeature)[]>([]);

	useEffect(() => {
		if (
			!map ||
			map
				.getLayers()
				.getArray()
				.find((l) => l.get("id") === "highlight")
		)
			return;

		map.addLayer(HIGHLIGHT_LAYER);
	}, [map]);

	useEffect(() => {
		if (!map) return;

		const removeCurrHighlight = () => {
			HIGHLIGHT_LAYER.getSource()?.clear(true);
			features.current.forEach((f) => {
				const props = f.getProperties();
				if (props) {
					if (f instanceof Feature && f?.get("internal")?.["style"]) {
						const internal = props["internal"];
						const style = internal["style"];
						f!.setProperties(
							{
								...props,
								internal: {
									...internal,
									highlighted: false,
									style: style?.map((s: Style) =>
										(s as any).isHighlighted_ ? getPointShadow(internal.icon.split("/")[internal.icon.split("/").length - 1].split("_")[0] === "s") : s
									),
								},
							},
							true
						);
					} else {
						const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
						if (layer) {
							setFeatureState(layer as any, { id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"], source: props["layer"] }, undefined);
						}
					}
				}
			});
			features.current = [];
		};

		const keyEvent = map.on("pointermove", (e) => {
			const [feat] = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 2 });

			if (feat && features.current.find((f) => f.getId() === feat.getId())) return;

			if (feat && !feat.get("features") && feat.getProperties()["internal"]) {
				removeCurrHighlight();
				if (feat instanceof Feature && !features.current.find((f) => f.getId() === feat.getId()) && feat.getGeometry() instanceof Point) {
					const styleDef = getStyle(feat, feat.get("internal"));
					const style = findStyle({ table: feat.get("layer").replace("public.", "") }, feat, styleDef);
					const clone = feat.clone();
					clone.setStyle(style?.map((s: Style) => ((s as any).isShadow_ ? getPointHighlight() : s)));
					HIGHLIGHT_LAYER.getSource()?.addFeature(clone);
				}
				const props = feat.getProperties();
				if (props["linked_to"] || (feat instanceof Feature && feat?.get("internal")?.["style"])) {
					const linkedFeature =
						feat instanceof Feature
							? feat
							: COOLER_CLUSTER_SOURCE.getFeatures().find(
									(f) => f.get("id") === props["internal"]["table"] + "-" + props["id"] + "-" + props["layer"] + "-" + props["linked_to"]
							  );
					if (linkedFeature) {
						const props = linkedFeature.getProperties();
						const internal = props["internal"];
						const style = internal["style"];
						linkedFeature.setProperties(
							{
								...props,
								internal: {
									...internal,
									highlighted: true,
									style: style?.map((s: Style) =>
										(s as any).isShadow_ ? getPointHighlight(internal.icon.split("/")[internal.icon.split("/").length - 1].split("_")[0] === "s") : s
									),
								},
							},
							true
						);
						features.current = [...features.current, linkedFeature];
					}
				}
				if (feat instanceof RenderFeature || !feat?.get("internal")?.["style"]) {
					const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
					if (layer) {
						features.current = [...features.current, feat];
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"], source: props["layer"] },
							{ highlighted: true }
						);
					}
				}
			} else {
				removeCurrHighlight();
			}
		});

		return () => {
			map.un("pointermove", keyEvent.listener);
		};
	}, [map]);

	return <></>;
};
