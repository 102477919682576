import { DatePicker } from "antd";
import "./Datepicker.css";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../map/Map.repository";
import VectorTile from "ol/source/VectorTile";
import Layer from "ol/layer/Layer";
import { Feature } from "ol";
import { useTranslation } from "react-i18next";
import { LAYER_CACHE } from "./Data-layers-cluster";
import VectorTileLayer from "ol/layer/VectorTile";

let storage: any = {};
let featureStorage: any = {};

export const DataLayersDatePicker = () => {
	const [store] = useObservable(getMapStore());
	const [t] = useTranslation();

	const removeFeatures = (l: Layer) => {
		const id = l.get("id");
		const map = store.map!;
		const clusterLayer = map.getAllLayers().find((l) => l.get("id") === "cluster");
		const clusterSource = (clusterLayer?.getSource() as any).getSource();
		const features = LAYER_CACHE[id];
		features?.forEach((feature: Feature) => {
			clusterSource?.removeFeature(feature);
		});
		if (!featureStorage[id]) featureStorage[id] = features;
	};

	const addFeatures = (l: Layer) => {
		const id = l.get("id");
		const map = store.map!;
		const clusterLayer = map.getAllLayers().find((l) => l.get("id") === "cluster");
		const clusterSource = (clusterLayer?.getSource() as any).getSource();
		const features = featureStorage[id];
		LAYER_CACHE[id]?.forEach((f) => clusterSource?.removeFeature(f));
		if (features) {
			clusterSource?.addFeatures(features);
			LAYER_CACHE[id] = features;
			delete featureStorage[id];
		}
	};

	return (
		<div className="relative w-32 h-8">
			<input
				className="p-1 z-50 px-2 border-2 border-neutral-300 rounded-sm focus:ring hover:ring-2 focus:ring-npp-blue-500 hover:ring-npp-blue-500 focus:outline-none text-sm w-full"
				placeholder={t("date") + "..."}
				id="custom-datepicker"
				onClick={() => {
					(document.getElementsByClassName("ant-picker")[0] as HTMLElement).click();
				}}
				onChange={(e) => {
					if (e.target.value === "") {
						store
							.map!.getAllLayers()
							.filter((l) => ["kafkamessages_plannedevent", "kafkamessages_notplannedevent"].includes(l.get("table")))
							.forEach((l) => {
								const url = storage[l.get("id")];
								addFeatures(l);
								(l.getSource() as VectorTile).setUrl(url);
								l.getSource()!.changed();
							});
					}
				}}
			/>
			<DatePicker.RangePicker
				format={"DD/MM/YYYY"}
				className="rounded-sm z-[-10] left-0 border-neutral-300 focus:ring hover:ring-2 ring-npp-blue-500 focus:outline-none transition-none absolute invisible -top-6 h-0"
				onChange={(e) => {
					const [start, end] = e as any;
					const el = (document.getElementById("custom-datepicker") as HTMLInputElement)!;
					el.value = `${start.$D}/${start.$M + 1}/${start.$y}`;
					store
						.map!.getAllLayers()
						.filter((l) =>
							["kafkamessages_plannedevent", "kafkamessages_notplannedevent"].find(
								(v) => v === l.get("table") || (l instanceof VectorTileLayer && l.getSource()?.getUrls()?.[0].includes(v))
							)
						)
						.forEach((l) => {
							const source = l.getSource() as VectorTile;
							const [url] = source.getUrls()!;
							if (!storage?.[l.get("id")]) storage = { ...storage, [l.get("id")]: url };
							removeFeatures(l);
							source.setUrl(
								(url.includes("?from_date") ? storage?.[l.get("id")] : url) +
									`?from_date=${start.$y}-${start.$M + 1}-${start.$D}&to_date=${end.$y}-${end.$M + 1}-${end.$D}`
							);
							source.changed();
						});
				}}
			/>
		</div>
	);
};
