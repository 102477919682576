import { useCallback, useEffect, useState } from "react";
import MapMarker, { MARKER_LAYER } from "./Map-marker";
import { MapDrawControlProps, MapMarkerDrawControls } from "./controls/Map-marker-draw-controls";
import { Draw, Modify } from "ol/interaction";
import { Cookies } from "../interceptors/Cookies";

interface Props {
	options: { readOnly: boolean; objectId: string; formType?: string; onlyOne?: boolean; detour?: boolean };
}

let listener: any;

export const MapMarkerWrapper = (props: Props) => {
	const [value, setValue] = useState<MapDrawControlProps>({});
	const [disable, setDisable] = useState(false);

	useEffect(
		() => () => {
			const string = localStorage.getItem("lvc-geom");
			if (string) {
				let object = JSON.parse(string);
				delete object[props.options.objectId ?? "new"];
				localStorage.setItem("lvc-geom", JSON.stringify(object));
			}
		},
		[props.options.objectId]
	);

	useEffect(() => {
		const map = value.map;

		if (map) {
			const interactions = map.getInteractions().getArray();
			interactions.forEach((i) => {
				if (i instanceof Draw) {
					i.setActive(false);
				}
			});
			const modify = interactions.find((i) => i instanceof Modify);
			if (modify) modify.setActive(!props.options.readOnly);
		}
	}, [props.options.readOnly, value.map]);

	const checkIfOnlyOne = useCallback(() => {
		const layer = value.layer;
		const map = value.map;

		if (layer && map) {
			const features = layer.getSource()!.getFeatures();
			const value = !!props.options.onlyOne && features.length === 1;
			setDisable(value);
			if (value)
				map
					.getInteractions()
					.getArray()
					.filter((i) => i.get("group") === "draw")
					.forEach((i) => i.setActive(false));
		}
	}, [props.options.onlyOne, value.layer, value.map]);

	useEffect(() => {
		const layer = value.layer;

		if (layer && props.options.onlyOne !== undefined) {
			checkIfOnlyOne();

			if (listener) layer.getSource()!.un(listener.type, listener.listener);
			listener = layer.getSource()!.on("change", () => {
				checkIfOnlyOne();
			});
		}
	}, [checkIfOnlyOne, props.options.onlyOne, value.layer]);

	useEffect(
		() => () => {
			MARKER_LAYER.getSource()?.clear();
		},
		[]
	);

	return (
		<MapMarker onChange={() => {}} setValue={setValue}>
			<Cookies></Cookies>
			<div className="absolute bottom-12 right-2 flex items-center justify-center">
				{!props.options.readOnly && (
					<MapMarkerDrawControls
						map={value.map}
						layer={value.layer}
						objectId={props.options.objectId}
						type={props.options.formType}
						disable={disable}
						detour={props.options.detour}
					></MapMarkerDrawControls>
				)}
			</div>
		</MapMarker>
	);
};
