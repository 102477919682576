import { useCallback, useEffect } from "react";

import { useObservable } from "@ngneat/use-observable";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

import { getMapStore } from "../Map.repository";
import { getDrawStyle } from "../../map-marker/controls/Draw-styles";

function BasicVectorLayer({ properties }: any) {
	const [map] = useObservable(getMapStore());

	const loadDataOnlyOnce = useCallback(() => {
		const vectorSource = new VectorSource({});

		const vectorLayer = new VectorLayer({
			source: vectorSource,
			properties: properties,
			zIndex: 100,
			style: getDrawStyle(),
		});
		map.map?.addLayer(vectorLayer);
	}, [map, properties]);

	useEffect(() => {
		if (!map?.map) return;
		loadDataOnlyOnce();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	return <></>;
}

export default BasicVectorLayer;
