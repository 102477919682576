import { LineStyle, PointStyle } from "../../../store/Layers.repository";
import { LegendLineItem } from "./Legend-line-item";
import { LegendListItem } from "./Legend";

export default function LegendList({ items }: { items: LegendListItem[] }) {
	return (
		<ul className="pt-2 overflow-y-auto space-y-3 scroll-bar max-h-[95%]">
			{items.map((item, i) => {
				const legend = item.legend;

				return (
					<li key={i}>
						<p className="font-semibold leading-tight mb-1">{item.name}</p>
						<ul className="space-y-1 pl-4">
							{legend.lines.map((l: LineStyle, i: number) => (
								<LegendLineItem key={i} l={l}></LegendLineItem>
							))}
							{legend.points.map((p: PointStyle, i: number) => (
								<li key={i} className="flex gap-2 items-center">
									{p.piktogram ? (
										<img src={p.piktogram} alt="piktogram" className="h-10 w-10" />
									) : item.name.includes("UVIS") ? (
										<div className="h-10 w-10 relative shrink-0 flex items-center justify-center">
											<div
												style={{
													background: p.color,
												}}
												className="h-2 w-2 rounded-full absolute"
											></div>
											<div
												style={{
													background: p.color,
													opacity: 0.5,
												}}
												className="h-10 w-10 rounded-full absolute"
											></div>
										</div>
									) : (
										<div
											style={{
												background: p.color,
											}}
											className="h-4 w-4 rounded-full ml-2 shrink-0"
										></div>
									)}
									<p className="ml-4">{p.name}</p>
								</li>
							))}
						</ul>
					</li>
				);
			})}
		</ul>
	);
}
