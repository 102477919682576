export const SHIELD_V = (label: string) => ({
	type: "symbol",
	id: "road_shield_1",
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]], ["==", ["get", "ac_type"], "V"]],
	layout: {
		"icon-image": "gray-box",
		"icon-rotation-alignment": "viewport",
		"symbol-placement": "point",
		"symbol-spacing": 500,
		"text-field": `{${label}}`,
		"text-font": ["Roboto Bold"],
		"text-offset": [0, 0.1],
		"text-rotation-alignment": "viewport",
		"text-size": 10,
		"icon-size": 0.9,
		"symbol-z-order": "auto",
		"text-pitch-alignment": "viewport",
		"symbol-avoid-edges": false,
	},
	paint: {
		"icon-translate-anchor": "map",
		"text-color": "rgba(255, 255, 255, 1)",
	},
});

export const SHIELD_P = (label: string) => ({
	type: "symbol",
	id: "road_shield_2",
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]], ["==", ["get", "ac_type"], "P"]],
	layout: {
		"icon-image": "blue-box",
		"icon-rotation-alignment": "viewport",
		"symbol-placement": "point",
		"symbol-spacing": 500,
		"text-field": `{${label}}`,
		"text-font": ["Roboto Bold"],
		"text-offset": [0, 0.1],
		"text-rotation-alignment": "viewport",
		"text-size": 10,
		"icon-size": 0.9,
		"symbol-z-order": "auto",
		"text-pitch-alignment": "viewport",
		"symbol-avoid-edges": false,
	},
	paint: {
		"icon-translate-anchor": "map",
		"text-color": "rgba(255, 255, 255, 1)",
	},
});

export const SHIELD_A = (label: string) => ({
	type: "symbol",
	id: "road_shield_3",
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]], ["==", ["get", "ac_type"], "A"]],
	layout: {
		"icon-image": "red-box",
		"icon-rotation-alignment": "viewport",
		"symbol-placement": "point",
		"symbol-spacing": 500,
		"text-field": `{${label}}`,
		"text-font": ["Roboto Bold"],
		"text-offset": [0, 0.1],
		"text-rotation-alignment": "viewport",
		"text-size": 10,
		"icon-size": 0.9,
		"symbol-z-order": "auto",
		"text-pitch-alignment": "viewport",
		"symbol-avoid-edges": false,
	},
	paint: {
		"icon-translate-anchor": "map",
		"text-color": "rgba(255, 255, 255, 1)",
	},
});
