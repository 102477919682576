export default function SearchBarComponent(props: { placeholder: string }) {
	return (
		<input
			className="w-[482px] h-[41px] border-2 border-[#717273] pl-5 hover:outline hover:outline-npp-blue-500 hover:outline-4 hover:opacity-80"
			type="text"
			id="fname"
			name="firstname"
			placeholder={props.placeholder}
		/>
	);
}
