import { CustomSwitcher } from "./Custom-switcher";
import Button1Component from "./button-1";
import Button2Component from "./button-2";
import Button3Component from "./button-3";
import Checkbox1Component from "./checkbox-1";
import Radio1Component from "./radio-1";
import SearchBarComponent from "./search-bar";
import Tag1Component from "./tag-1";
import Tag4Component from "./tag-4";

function UiKit() {
	return (
		<>
			<div className="container flex flex-col items-center justify-center font-montserrat h-[100vh]">
				<CustomSwitcher value={true} onChange={(e: any) => console.log(e)}></CustomSwitcher>
				<SearchBarComponent placeholder={"@  Lorum"}></SearchBarComponent>
				<div className="h-4"></div>
				<Button1Component text={"Abonēt datu kopu"}></Button1Component>
				<div className="h-4"></div>
				<Button2Component text={"Reģistrēties"}></Button2Component>
				<div className="h-4"></div>
				<Button3Component text={"Labot"}></Button3Component>
				<div className="h-4"></div>
				<Checkbox1Component label={"Pievienojot datnes"} id={"Pievienojot"} name={"test"}></Checkbox1Component>
				<div className="h-4"></div>
				<Radio1Component label={"Pievienojot datnes"} id={"pievi"} name={"objekti"}></Radio1Component>
				<Radio1Component label={"test datnes"} id={"test"} name={"objekti"}></Radio1Component>
				<div className="h-4"></div>
				<Tag4Component text={"API"}></Tag4Component>
				<div className="h-4"></div>
				<Tag1Component text={"Operatīvie dati"}></Tag1Component>
			</div>
		</>
	);
}

export default UiKit;
