import { Feature } from "ol";
import { Circle, Fill, Stroke, Style } from "ol/style.js";
import { addEndsToLineString } from "../../data-layers/Style-generator-turf";
import { MultiLineString } from "ol/geom";

const blue = "#0000ff";
const width = 3;

export const getDrawStyle = (feature?: Feature, r?: number, colour?: string) => {
	if (feature?.get('detour')) {
		colour =  "rgb(204, 204, 0)"
	}

	let styles = [
		new Style({
			stroke: new Stroke({
				color: "#ffffff",
				width: width + 2,
			}),
		}),
		new Style({
			fill: new Fill({
				color: [255, 255, 255, 0.3],
			}),
			stroke: new Stroke({
				color: colour ?? blue,
				width: width,
			}),
		}),
		new Style({
			image: new Circle({
				radius: width * 2,
				stroke: new Stroke({
					color: colour ?? blue,
					width: width,
				}),
			}),
			zIndex: Infinity,
		}),
	];

	if (r && feature?.getGeometry()?.getType() === "MultiLineString") {
		const ends = (feature.getGeometry() as MultiLineString)
			.getLineStrings()
			.map((l) => {
				l.setCoordinates(l.getCoordinates().map((coord) => coord.filter((c, i) => i === 0 || i === 1)));
				return addEndsToLineString(
					new Feature({
						geometry: l,
					}),
					{ color: colour ?? blue, width: width } as any,
					20 * r > 300 ? 300 : 20 * r
				);
			})
			.flat();
		styles = [...styles, ends].flat();
	}
	return styles;
};
