import { Geometry } from "ol/geom";
import { Feature } from "ol";
import { MARKER_LAYER } from "../Map-marker";
import { useEffect } from "react";

export let UNDO_REDO_HISTORY: Feature<Geometry>[][] = [];

export const updateUndoRedoHistory = (features: Feature<Geometry>[]) => {
  if (
    (HISTORY_INDEX || HISTORY_INDEX === 0) &&
    HISTORY_INDEX !== UNDO_REDO_HISTORY.length - 1
  ) {
    UNDO_REDO_HISTORY.splice(
      HISTORY_INDEX > -1 ? HISTORY_INDEX + 1 : 0,
      UNDO_REDO_HISTORY.length - HISTORY_INDEX
    );
  }
  if (HISTORY_INDEX === undefined) {
    HISTORY_INDEX = UNDO_REDO_HISTORY.length;
  } else if (HISTORY_INDEX === UNDO_REDO_HISTORY.length - 1) {
    HISTORY_INDEX = HISTORY_INDEX + 1;
  }
  UNDO_REDO_HISTORY.push(features);
};

let HISTORY_INDEX: number;

export const MapUndoRedoControl = () => {
  useEffect(() => {
    UNDO_REDO_HISTORY = [];
  }, []);

  const undo = () => {
    const source = MARKER_LAYER.getSource()!;
    if (HISTORY_INDEX - 1 > -2) {
      HISTORY_INDEX = HISTORY_INDEX - 1;
      source.clear();
      source.addFeatures(
        UNDO_REDO_HISTORY[HISTORY_INDEX]?.map((f) => {
          f.set("DONT_UPDATE", true);
          return f;
        }) ?? []
      );
    }
  };

  const redo = () => {
    const source = MARKER_LAYER.getSource()!;
    if (HISTORY_INDEX + 1 !== UNDO_REDO_HISTORY.length) {
      HISTORY_INDEX = HISTORY_INDEX + 1;
      source.clear();
      source.addFeatures(
        UNDO_REDO_HISTORY[HISTORY_INDEX]?.map((f) => {
          f.set("DONT_UPDATE", true);
          return f;
        }) ?? []
      );
    }
  };

  return (
    <>
      <button
        onClick={undo}
        className="bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md"
      >
        <span className="material-icons">undo</span>
      </button>
      <button
        onClick={redo}
        className="bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md"
      >
        <span className="material-icons">redo</span>
      </button>
    </>
  );
};
