import { useObservable } from "@ngneat/use-observable";
import { useEffect, useRef, useState } from "react";
import LegendList from "./Legend-list";
import { getSettingStore } from "../../../settings/Settings.repository";
import { useTranslation } from "react-i18next";
import { StyleLayerProp, getStyleItems } from "../../../data-layers/Style-layer.repository";
import { take } from "rxjs";
import { Control } from "ol/control";
import { getMapStore } from "../../Map.repository";
import { isMobile } from "react-device-detect";

export interface LegendListItem {
	name: string;
	legend: {
		lines: any[];
		points: any[];
	};
}

let detourFound = false;

export default function LegendComponent() {
	const [open, setOpen] = useState(false);
	const [legendItems, setLegendItems] = useState<LegendListItem[]>([]);
	const [styleStore] = useObservable(getStyleItems());
	const [setting] = useObservable(getSettingStore());
	const [mapStore] = useObservable(getMapStore());
	const legendRef = useRef<HTMLDivElement | null>(null);
	const [t] = useTranslation();

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	const createItems = (array: StyleLayerProp[]) => {
		detourFound = false;
		const items = array
			.filter((g) => g.visible)
			.map((group) => {
				const names = new Set(group.layers.filter((l) => l.visible).map((l) => l.name));
				return Array.from(names).map((n) => {
					const items = group.layers.filter((l) => l.name === n);

					const lines: any[] = [];
					items
						.map((i) => i.lines)
						.flat()
						.forEach((l) => {
							if (!detourFound && l.source?.includes("plannedevent")) {
								detourFound = true;
							}
							const item = { ...l, id: undefined, filter: undefined, layer_id: undefined, source: null, attributes: null, label_style: null, label: null };
							if (!lines.find((l) => JSON.stringify(l) === JSON.stringify(item))) lines.push(item);
						});
					const points: any[] = [];
					items
						.map((i) => i.points)
						.flat()
						.forEach((l) => {
							const item = { ...l, id: undefined, filter: undefined, layer_id: undefined, source: null, attributes: null, label_style: null, label: null };
							if (!points.find((l) => JSON.stringify(l) === JSON.stringify(item))) points.push(item);
						});

					return { name: n, legend: { lines: lines, points: points } };
				});
			});

		if (detourFound) {
			const detour = {
				id: undefined,
				filter: undefined,
				layer_id: undefined,
				source: null,
				attributes: null,
				label_style: null,
				label: null,
				stroke_style: "detour",
				name: "Apbraukšanas maršruts",
			};
			const item = {
				legend: {
					lines: [detour],
					points: [],
				},
				name: "Apbraukšanas maršruts",
			};
			items.push([item]);
		}

		if (items.length > 0) setLegendItems(items.flat());
	};

	useEffect(() => {
		if (!legendRef.current || !mapStore.map) return;

		const legendControl = new Control({ element: legendRef.current });

		mapStore.map.addControl(legendControl);
	}, [legendRef, mapStore]);

	useEffect(() => {
		createItems(styleStore);
	}, [styleStore]);

	if (legendItems.length < 1)
		getStyleItems()
			.pipe(take(1))
			.subscribe((styleItems) => createItems(styleItems));

	return (
		<div ref={legendRef} className={classNames(setting.showLegendBtn ? "" : "hidden", `${isMobile ? "-z-10" : ""} top-24 right-2 sm:right-16 sm:top-4 absolute`)}>
			<button onClick={() => setOpen(!open)} className="bg-white p-4 drop-shadow-md rounded" title={t("legend") ?? ""}>
				<div className="h-[43px] w-[43px] flex items-center justify-center font-semibold border-red-900 border-2 p-3 text-3xl">
					<p>L</p>
				</div>
			</button>
			{open && (
				<div className="absolute flex flex-col right-0 top-0 sm:top-20 z-10 bg-white p-4 rounded w-[95vw] max-h-[70vh] sm:max-h-[85vh] sm:w-[26rem] border-2 border-npp-grey-500">
					<div className="flex justify-between items-center">
						<h2 className="uppercase  text-sm font-bold">{t("legend")}</h2>
						<button
							type="button"
							className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-npp-blue-500 focus:ring-offset-2 -mr-1"
							onClick={() => setOpen(false)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000">
								<path d="M0 0h24v24H0V0z" fill="none" />
								<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
							</svg>
							<span className="sr-only">Close</span>
						</button>
					</div>
					<LegendList items={legendItems} />
				</div>
			)}
		</div>
	);
}
