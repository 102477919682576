import { useObservable } from "@ngneat/use-observable";
import { getSearchStore } from "./Search.repository";
import { useEffect, useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { SearchResultItem } from "./Result-item";
import * as olUtil from "ol/util";

const defaultValues = { OSM: 4, VZD: 4, LVC: 4 };

export default function SearchResult({ onClear }: { onClear: any }) {
	const [count, setCount] = useState(defaultValues);
	const [active, setActive] = useState("");
	const [searchStore] = useObservable(getSearchStore());

	useEffect(() => {
		setCount(defaultValues);
	}, [searchStore.data]);

	if (!searchStore.data) return null;

	const lvc = searchStore.data.filter((d) => d.source === "LVC").filter((d, i) => count["LVC"] >= i);
	const vzd = searchStore.data.filter((d) => d.source === "VZD").filter((d, i) => count["VZD"] >= i);
	const osm = searchStore.data.filter((d) => d.source === "OSM").filter((d, i) => count["OSM"] >= i);

	return (
		<div className="z-1 rounded-b flex flex-col pointer-events-auto overflow-y-auto scroll-bar px-4 mr-4">
			{searchStore.data.length === 0 ? (
				<p className="text-gray-900 p-4">No results found</p>
			) : (
				<>
					{lvc.length > 0 && (
						<>
							<h4 className="font-bold pb-1 pt-4 text-npp-grey-500">LVC CEĻU TĪKLS</h4>
							<ul className="divide-y-2">
								{lvc.map((d) => (
									<SearchResultItem
										key={d.display_name}
										item={d}
										onClick={() => setActive(olUtil.getUid(d.feature) === active ? "" : olUtil.getUid(d.feature))}
										active={active === olUtil.getUid(d.feature)}
									></SearchResultItem>
								))}
								{searchStore.data.filter((d) => d.source === "LVC").length > count["LVC"] && (
									<li
										onClick={() => setCount({ ...count, LVC: count["LVC"] + 5 })}
										className="py-4 text-npp-blue-500 font-semibold underline underline-offset-2 cursor-pointer flex items-center"
									>
										Ielādēt vairāk <CaretDownOutlined rev={undefined} className="ml-2" />
									</li>
								)}
							</ul>
						</>
					)}
					{vzd.length > 0 && (
						<>
							<h4 className="font-bold pb-1 pt-4 text-npp-grey-500">VZD ADREŠU REĢISTRS</h4>
							<ul className=" divide-y-2">
								{vzd.map((d) => (
									<SearchResultItem
										key={d.display_name}
										item={d}
										onClick={() => setActive(olUtil.getUid(d.feature) === active ? "" : olUtil.getUid(d.feature))}
										active={active === olUtil.getUid(d.feature)}
									></SearchResultItem>
								))}
								{searchStore.data.filter((d) => d.source === "VZD").length > count["VZD"] && (
									<li
										onClick={() => setCount({ ...count, VZD: count["VZD"] + 5 })}
										className="py-4 text-npp-blue-500 font-semibold underline underline-offset-2 cursor-pointer flex items-center"
									>
										Ielādēt vairāk <CaretDownOutlined rev={undefined} className="ml-2" />
									</li>
								)}
							</ul>
						</>
					)}
					{osm.length > 0 && (
						<>
							<h4 className="font-bold pb-1 pt-4 text-npp-grey-500">OSM</h4>
							<ul className=" divide-y-2">
								{osm.map((d) => (
									<SearchResultItem
										key={d.display_name}
										item={d}
										onClick={() => setActive(olUtil.getUid(d.feature) === active ? "" : olUtil.getUid(d.feature))}
										active={active === olUtil.getUid(d.feature)}
									></SearchResultItem>
								))}
								{searchStore.data.filter((d) => d.source === "OSM").length > count["OSM"] && (
									<li
										onClick={() => setCount({ ...count, OSM: count["OSM"] + 5 })}
										className="py-4 text-npp-blue-500 font-semibold underline underline-offset-2 cursor-pointer flex items-center"
									>
										Ielādēt vairāk <CaretDownOutlined rev={undefined} className="ml-2" />
									</li>
								)}
							</ul>
						</>
					)}
				</>
			)}
			<hr className="-mr-4" />
		</div>
	);
}
