const LogoSVG = (props: any) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={374} height={474} viewBox="0 0 280 355" {...props}>
		<style>{".s2{fill:#9e3039}"}</style>
		<g id="Layer 1">
			<path
				id="&lt;Path&gt;"
				d="M266.6 80.4c-52 28.8-48.4 57.2-49.6 67.6-9.2 56-76.8 56.8-76.8 56.8S72.6 204 63.4 148c-1.6-10.4 2.4-39.2-49.6-67.6 0 0-72.4 77.6 126.4 274 198.8-196 126.4-274 126.4-274z"
				style={{
					fill: "#4c4e4f",
				}}
			/>
			<path
				d="m145 16.4-19.2 24.4L117 30c-47.2 38.4-27.2 9.6-74.4 48C43 78.4 53 90.8 53 90.8l6.4 6.4 5.6 8.4c.4.4 12.8 16 13.6 16.4L141 82l61.6 40.8c.4-.8 12.8-16 13.2-16.8 0 0 5.2-8.4 5.6-8.8l6.4-6.4c.4-.4 10-12.8 10.4-13.2C177.8 30 205.4 64.4 145 16.4z"
				style={{
					fill: "#fff",
				}}
			/>
			<path
				d="M140.2 73.6c-7.6 0-48.4 2-74.4 33.6 0 0 10.8 15.6 11.2 24.4.8 8.8 2.8 18.4 2.8 18.4s9.6-21.2 42.8-28c6-1.2 11.6-2 17.6-2 6 0 12 .8 17.6 2 33.2 6.8 42.8 28 42.8 28s2-9.6 2.8-18.4c.8-8.8 11.2-24.4 11.2-24.4-25.6-31.6-66.4-33.6-74.4-33.6z"
				className="s2"
			/>
			<path
				d="M140.2 0c-10.8-.4-80.4 1.2-120 68 0 0 22.8 13.6 32.8 23.6 0 0 22-44 87.2-44.4 65.6.4 87.2 44.4 87.2 44.4 10-10 33.2-23.2 33.2-23.2C220.2.8 151-.4 140.2 0z"
				className="s2"
			/>
		</g>
	</svg>
);
export default LogoSVG;
