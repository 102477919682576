import { useObservable } from "@ngneat/use-observable";
import { useEffect, useState } from "react";
import { getMapStore } from "../Map.repository";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "../Map";

export const MapAddControls = () => {
	const [value, setValue] = useState(false);
	const [coords, setCoords] = useState("");
	const [mapStore] = useObservable(getMapStore());
	const [t] = useTranslation();

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		const view = map.getView();

		setCoords("?location=" + view.getCenter() + "," + view.getZoom());
		view.on("change:center", () => {
			setCoords("?location=" + view.getCenter() + "," + view.getZoom());
		});
	}, [mapStore]);

	return (
		<div className="absolute bottom-14 right-2 z-10 box-border">
			{value ? (
				<>
					{PERMISSIONS.includes("Neparedzēta notikuma pievienošana") && (
						<a
							target="_blank"
							title={t("addUnplanned") ?? ""}
							href={(window.location.origin.includes("localhost") ? window.location.origin + "/mini" : window.location.origin + "/unexpected_events/add") + coords}
							rel="noreferrer"
						>
							<button className="bg-white p-6 border-2 mb-4 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md">
								<span className="h-6 w-6 font-bold">N</span>
							</button>
						</a>
					)}
					{PERMISSIONS.includes("Plānota notikuma pievienošana") && (
						<a
							target="_blank"
							title={t("addPlanned") ?? ""}
							href={(window.location.origin.includes("localhost") ? window.location.origin + "/mini" : window.location.origin + "/planned_events/add") + coords}
							rel="noreferrer"
						>
							<button className="bg-white p-6 border-2 mb-4 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md">
								<span className="h-6 w-6 font-bold">P</span>
							</button>
						</a>
					)}
					{PERMISSIONS.includes("Statisku objektu augšupielāde (imports)") && (
						<a
							target="_blank"
							title={t("addStatic") ?? ""}
							href={(window.location.origin.includes("localhost") ? window.location.origin + "/mini" : window.location.origin + "/static_objects/new") + coords}
							rel="noreferrer"
						>
							<button className="bg-white p-6 border-2 mb-4 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md">
								<span className="h-6 w-6 font-bold">S</span>
							</button>
						</a>
					)}
					<button
						onClick={() => setValue(!value)}
						className="bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md"
					>
						<span className="material-icons">close</span>
					</button>
				</>
			) : (
				!!["Statisku objektu augšupielāde (imports)", "Plānota notikuma pievienošana", "Neparedzēta notikuma pievienošana"].find((i) => PERMISSIONS.includes(i)) && (
					<button
						onClick={() => setValue(!value)}
						className="bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md"
					>
						<span className="material-icons">add</span>
					</button>
				)
			)}
		</div>
	);
};
