import { useEffect, useState } from "react";
import { MapDrawControlProps, MapMarkerDrawControls } from "../../map-marker/controls/Map-marker-draw-controls";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../Map.repository";
import WKT from "ol/format/WKT";
import { Modify } from "ol/interaction";
import { getDrawStyle } from "../../map-marker/controls/Draw-styles";

const LAYER = new VectorLayer({
	source: new VectorSource(),
	zIndex: Infinity,
	properties: {
		id: "draw",
	},
	style: getDrawStyle(),
});

const MODIFY = new Modify({
	source: LAYER.getSource()!,
});

export const MapDrawControlWrapper = () => {
	const [value, setValue] = useState<MapDrawControlProps>({});
	const [objectId] = useState(window.location.search.replace("?edit=", ""));
	const [mapStore] = useObservable(getMapStore());

	useEffect(() => {
		if (!mapStore.map) return;
		LAYER.setMap(mapStore.map);
		mapStore.map.addInteraction(MODIFY);
		setValue({ map: mapStore.map, layer: LAYER });
	}, [mapStore]);

	useEffect(() => {
		const string = localStorage.getItem("lvc-geom");
		if (string) {
			const features = JSON.parse(string)[objectId]?.map((i: { geom: string; uid: string }) => {
				const f = new WKT().readFeature(i.geom);
				f.setId(i.uid);
				return f;
			});
			if (features) LAYER.getSource()?.addFeatures(features);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="absolute bottom-12 right-4 flex items-center justify-center">
			<MapMarkerDrawControls map={value.map} layer={value.layer} objectId={objectId}></MapMarkerDrawControls>
		</div>
	);
};
