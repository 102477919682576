import GeoJSON from "ol/format/GeoJSON.js";
import { Feature } from "ol";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "./Map.repository";
import VectorSource from "ol/source/Vector";
import { useCallback, useEffect } from "react";
import { Circle, Fill, Stroke, Style } from "ol/style";
import WKT from "ol/format/WKT.js";

export interface NominatimObject {
	osm_type: string;
	osm_id: any;
	display_name: string;
	type_displayname: string;
	feature: Feature;
	source: "OSM";
}

export interface SearchResultObject {
	display_name: string;
	source: string;
	feature: Feature;
}

export interface Geojson {
	type: string;
	coordinates: number[];
}

function getStyle() {
	return new Style({
		stroke: new Stroke({
			width: 4,
			color: "orange",
		}),
		image: new Circle({
			fill: new Fill({
				color: "rgb(255 195 45 / 0.5)",
			}),
			stroke: new Stroke({
				width: 3,
				color: "orange",
			}),
			radius: 5,
		}),
	});
}

export function readGJSONFeature(geometry: string) {
	const reader = new GeoJSON();
	return reader.readFeature(geometry, {
		dataProjection: "EPSG:4326",
		featureProjection: "EPSG:3857",
	});
}

export function readWKTFeature(geometry: string, projection?: string) {
	const reader = new WKT();
	return reader.readFeature(geometry, {
		dataProjection: projection ?? "EPSG:3059",
		featureProjection: "EPSG:3857",
	});
}

function SimpleFeature(object: any) {
	const [map] = useObservable(getMapStore());

	const loadDataOnlyOnce = useCallback(() => {
		const feature = readGJSONFeature(object.feature);
		//@ts-expect-error
		feature.setId(object.feature.osm_id);
		map.map?.getAllLayers().forEach((layer) => {
			if (layer.getProperties().id === "search") {
				//@ts-expect-error
				feature.setStyle(getStyle());
				(layer.getSource() as VectorSource<any>).addFeature(feature);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [object]);

	useEffect(() => {
		if (!map?.map) return;
		loadDataOnlyOnce();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map?.map]);

	return <></>;
}

export default SimpleFeature;
