/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from "react";
import { GlobalSettingsContext } from "../../Settings";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../Map.repository";
import { Feature, Map } from "ol";
import { WEBSOCKET_URL } from "../Endpoints";
import { VectorTile } from "ol/source";
import { TABLE_LIST, createSource } from "../../data-layers/Data-layers-new-layer-manager";
import VectorTileLayer from "ol/layer/VectorTile";
import { getStyleItems } from "../../data-layers/Style-layer.repository";
import { take } from "rxjs";
import { COOLER_LAYER_CACHE, createCoolerClusterSource } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { COOLER_CLUSTER_SOURCE } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster";

let MOVING = false;
let REFRESH = false;
let REFRESHING = false;

export const MapWebSocket = () => {
	const { refresh_rate } = useContext(GlobalSettingsContext);
	const [mapStore] = useObservable(getMapStore());

	const refreshLayers = (map: Map) => {
		if (!REFRESHING) {
			REFRESHING = true;
			let refreshCount = 0;
			getStyleItems()
				.pipe(take(1))
				.subscribe((styleLayers) => {
					console.log("refreshLayers");
					map.getAllLayers().forEach((l) => {
						let item: any;

						const group = styleLayers.find((sl) => {
							return sl.layers.find((layer) => {
								if (layer.table === l.get("table")) {
									item = layer;
									return true;
								}
								return false;
							});
						});

						if ((group?.visible && item.visible) || (l.getVisible() && l.get("type") === "data")) {
							if (
								l.get("table")?.includes("kafkamessages") ||
								l.get("url")?.[0]?.includes("kafkamessages") ||
								(l.getSource() as VectorTile)?.getUrls()?.[0]?.includes("kafkamessages")
							) {
								refreshCount = refreshCount + 1;
								const source = l.getSource() as VectorTile;

								if (l.get("clustered")) {
									let tableItem = TABLE_LIST.find((tableItem) => tableItem.table === l.get("table"))!;
									tableItem = { ...tableItem, layers: tableItem.layers.filter((l) => l.clustered) };
									COOLER_LAYER_CACHE[l.get("id")]?.forEach((item) => item.set("justUpdated", false, true));
									const newSource = createCoolerClusterSource(tableItem.merged_tiles ?? tableItem.url, tableItem, tableItem.table, map, true);
									const layer = new VectorTileLayer({
										zIndex: -10000,
										source: newSource,
									});
									layer.setStyle(l.get("style"));
									let count = 0;
									const l1 = newSource.on("tileloadstart", () => {
										count = count + 1;
									});
									const l2 = newSource.on("tileloadend", () => {
										count = count - 1;
										if (count === 0) {
											l.setSource(newSource);
											map.removeLayer(layer);
											newSource.un(l1.type as any, l1.listener);
											newSource.un(l2.type as any, l2.listener);
											COOLER_LAYER_CACHE[l.get("id")]
												?.filter((item) => item.get("justUpdated") === false)
												.forEach((item) => {
													item.set("removed", true, true);
												});
											refreshCount = refreshCount - 1;
											REFRESHING = refreshCount !== 0;

											COOLER_CLUSTER_SOURCE.changed();
											source.set("refresh", false, true);
										}
									});
									map.addLayer(layer);
								} else if (source.get("params")) {
									const newSource = createSource(source.get("params"));
									const layer = new VectorTileLayer({
										zIndex: -10000,
										source: newSource,
									});
									let count = 0;
									const l1 = newSource.on("tileloadstart", () => {
										count = count + 1;
									});
									const l2 = newSource.on("tileloadend", () => {
										count = count - 1;
										if (count === 0) {
											l.setSource(newSource);
											map.removeLayer(layer);
											newSource.un(l1.type as any, l1.listener);
											newSource.un(l2.type as any, l2.listener);
											refreshCount = refreshCount - 1;
											REFRESHING = refreshCount !== 0;
										}
									});
									map.addLayer(layer);
								} else {
									source.refresh();
									refreshCount = refreshCount - 1;
									REFRESHING = refreshCount !== 0;
								}
							}
						}
					});
				});
		}
	};

	useEffect(() => {
		const map = mapStore.map;
		if (map) {
			map.on("movestart", () => {
				MOVING = true;
			});

			map.on("moveend", () => {
				if (REFRESH) {
					refreshLayers(map);
					REFRESH = false;
				}
				MOVING = false;
			});

			setInterval(() => {
				if (!MOVING) {
					refreshLayers(map);
				} else {
					REFRESH = true;
				}
			}, refresh_rate * 1000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapStore]);

	const getURL = (table: string) => {
		return `wss://${WEBSOCKET_URL}/ws/${table.replace("_", "/")}`;
	};

	return (
		<>
			{/* Websockets are removed since a new way of layer refresh was introduced 
			{TABLES.map(t => (
				<Websocket key={t} socketUrl={getURL(t)} table={t}></Websocket>
			))} */}
		</>
	);
};
