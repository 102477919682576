import { useObservable } from "@ngneat/use-observable";
import { Input, Select } from "antd";
import { LinearRefResponse, StreetResponse, getStreetItems, updateStreetItems } from "./store/Streets.repository";
import axios from "axios";
import { ENTRYPOINT } from "../map/Endpoints";
import { useContext, useEffect, useRef, useState } from "react";
import { Feature } from "ol";
import { MultiLineString, Point, Polygon } from "ol/geom";
import { transform } from "ol/proj";
import WKT from "ol/format/WKT";
import { MAP, MARKER_LAYER } from "./Map-marker";
import { updateUndoRedoHistory } from "./controls/Map-undo-redo-control";
import { EventsKey } from "ol/events";
import VectorSource from "ol/source/Vector";
import * as ol from "ol/util";
import { FeatureListContext } from "./Map-marker-feature-list";
import { createEmpty } from "ol/extent";
import { extend } from "ol/extent";
import { BehaviorSubject } from "rxjs";

let timeout: any;
let eventsKey: EventsKey;
export const sticky$ = new BehaviorSubject(false);

interface ErrorMessage {
	error: "point_error" | "measure_error" | "general_error";
	message: string;
}

export const MapMarkerLinearRef = ({ feature, error: coordError, trigger }: { feature: Feature; error: (err: string) => void; trigger: boolean }) => {
	const [street, setStreet] = useState(feature.get("street") ?? "");
	const [start, setStart] = useState<string>(feature.get("start") ?? "");
	const [end, setEnd] = useState<string>(feature.get("end") ?? "");
	const [placeholder, setPlaceholder] = useState<{ min?: number; max?: number }>({ min: undefined, max: undefined });
	const [error, setError] = useState("");
	const touched = useRef({
		street: !!feature.get("street"),
		start: !!feature.get("start"),
		end: !!feature.get("end"),
	});
	const [geomState, setState] = useState("");

	const { readOnly, sticky, tag, detour } = useContext(FeatureListContext);
	sticky$.next(sticky);
	const [items] = useObservable(getStreetItems());
	const check = useRef(false);
	const zoom = useRef(false);

	const createErrorMsg = (data: ErrorMessage) => {
		feature.set("error", true);
		if (data.error === "point_error") {
			coordError(data.message);
			return "";
		}
		return data.message;
	};

	const clearErrorMsg = () => {
		coordError("");
		setError("");
		feature.set("error", false);
	};

	const checkReadOnly = (source: VectorSource<any>) => {
		const items = source.getFeatures().map((f) => f.getGeometry()?.getType() ?? "");
		if (items && items.length > 1) {
			setState(items[0]);
		} else {
			setState("");
		}
	};

	useEffect(() => {
		const source = MARKER_LAYER.getSource()!;
		checkReadOnly(source);
		eventsKey = source.on("change", () => checkReadOnly(source));
	}, []);

	useEffect(
		() => () => {
			const source = MARKER_LAYER.getSource()!;
			source.un("change", eventsKey.listener);
		},
		[]
	);

	useEffect(() => {
		if (feature.get("DONT_UPDATE") === true) {
			feature.set("DONT_UPDATE", false);
			return;
		}
		const geom = feature.getGeometry();
		if (geom instanceof Point && geom.getCoordinates()[0]) {
			const coords = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3059");
			if (coords[0] && coords[1]) {
				axios
					.post(ENTRYPOINT + "/lr/getlrandcoords", {
						point: { x: coords[0], y: coords[1] },
						tag: tag,
						not_sticky: !sticky$.value,
					})
					.then((response) => {
						const res = response.data[0];
						feature.setProperties({
							addresses: res.addresses,
							openlr: res.openlr,
						});

						if (sticky$.value) {
							const point = new WKT().readGeometry(res.geometry_wkt, { dataProjection: "EPSG:3059", featureProjection: "EPSG:3857" }) as Point;
							feature.setGeometry(point);
							feature.setProperties({ start: res.measure, street: res.route_id });
							setStreet(res.route_id);
							setStart(res.measure);
						}
						updateUndoRedoHistory(
							MARKER_LAYER.getSource()!
								.getFeatures()
								.map((f) => f.clone())
						);
						clearErrorMsg();
					})
					.catch((e) => {
						console.error(e);
						setError(createErrorMsg(e.response.data));
					});
			}
		}

		if (geom instanceof MultiLineString) {
			if (sticky$.value) {
				const start = transform(geom.getFirstCoordinate(), "EPSG:3857", "EPSG:3059");
				const end = transform(geom.getLastCoordinate(), "EPSG:3857", "EPSG:3059");
				axios
					.post(ENTRYPOINT + "/lr/getlrandcoords", {
						from_point: { x: start[0], y: start[1] },
						to_point: { x: end[0], y: end[1] },
						tag: tag,
					})
					.then((response) => {
						const res = response.data[0];
						feature.setProperties(
							{
								addresses: res.addresses,
								openlr: res.openlr,
							},
							true
						);
						if (sticky$.value) {
							feature.setGeometry(
								new WKT().readGeometry(res.geometry_wkt, {
									dataProjection: "EPSG:3059",
									featureProjection: "EPSG:3857",
								})
							);
							feature.setProperties({ start: res.from_measure, end: res.to_measure, street: res.route_id });
							setStreet(res.route_id);
							setStart(res.from_measure);
							setEnd(res.to_measure);
						}

						updateUndoRedoHistory(
							MARKER_LAYER.getSource()!
								.getFeatures()
								.map((f) => f.clone())
						);
						clearErrorMsg();
					})
					.catch((e) => {
						console.error(e);
						setError(createErrorMsg(e.response.data));
					});
			} else {
				axios
					.post(ENTRYPOINT + "/lr/getlrandcoords", {
						line: new WKT().writeGeometry(geom, { dataProjection: "EPSG:3059", featureProjection: "EPSG:3857" }),
						tag: tag,
					})
					.then((response) => {
						const res = response.data[0];
						feature.setProperties(
							{
								addresses: res.addresses,
								openlr: res.openlr,
							},
							true
						);
						updateUndoRedoHistory(
							MARKER_LAYER.getSource()!
								.getFeatures()
								.map((f) => f.clone())
						);
						clearErrorMsg();
					})
					.catch((e) => {
						console.error(e);
						setError(createErrorMsg(e.response.data));
					});
			}
		}

		if (geom instanceof Polygon) {
			axios
				.post(ENTRYPOINT + "/lr/getlrandcoords", {
					polygon: new WKT().writeGeometry(geom, {
						featureProjection: "EPSG:3857",
						dataProjection: "EPSG:3059",
					}),
					tag: tag,
				})
				.then((response) => {
					const features = response.data.map((d: LinearRefResponse) => {
						const f = new WKT().readFeature(d.geometry_wkt, {
							dataProjection: "EPSG:3059",
							featureProjection: "EPSG:3857",
						});
						f.setProperties({
							street: d.route_id,
							start: d.from_measure,
							end: d.to_measure,
							DONT_UPDATE: true,
							openlr: d.openlr,
						});
						return f;
					});
					MARKER_LAYER.getSource()!.removeFeature(feature);
					MARKER_LAYER.getSource()!.addFeatures(features);
					updateUndoRedoHistory(
						MARKER_LAYER.getSource()!
							.getFeatures()
							.map((f) => f.clone())
					);
					clearErrorMsg();
				})
				.catch((e) => {
					console.error(e);
					setError(createErrorMsg(e.response.data));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feature, trigger]);

	if (items.length < 1) {
		axios.get<StreetResponse>(ENTRYPOINT + "/api/caps/microroadnetwork/?limit=99999").then((response) => {
			updateStreetItems(response.data.results, response.data.next);
		});
	}

	useEffect(() => {
		if (timeout) clearTimeout(timeout);
		if (check.current) {
			timeout = setTimeout(() => checkValues(), 500);
			check.current = false;
			zoom.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start, end, street]);

	useEffect(() => {
		const t = touched.current;
		if (t.start && t.street && (!start || !street)) {
			setError("Lineārā reference ir obligāta");
		} else {
			clearErrorMsg();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start, street, touched]);

	const zoomOnGeom = () => {
		if (zoom.current) {
			zoom.current = false;
			const extent = createEmpty();
			MARKER_LAYER.getSource()!
				.getFeatures()
				.forEach((f) => extend(extent, f.getGeometry()?.getExtent() ?? []));
			MAP.getView().fit(extent, { maxZoom: 12, duration: 500 });
		}
	};

	const checkValues = () => {
		if (street && ((start && geomState !== "MultiLineString") || (start && end))) {
			const data = {
				route_id: street,
				...(start && end
					? { from_measure: Number(start.replaceAll(",", ".")), to_measure: Number(end.replaceAll(",", ".")), tag: tag, not_sticky: !sticky$.value }
					: { measure: start.replaceAll(",", "."), tag: tag, not_sticky: !sticky$.value }),
			};
			axios
				.post(ENTRYPOINT + "/lr/getlrandcoords", data)
				.then((response) => {
					if (start && end) {
						feature.setProperties(
							{
								addresses: response.data[0].addresses,
								start: response.data[0].from_measure,
								end: response.data[0].to_measure,
								street: response.data[0].route_id,
								openlr: response.data[0].openlr,
							},
							true
						);
						const geom = new WKT().readGeometry(response.data[0].geometry_wkt, {
							dataProjection: "EPSG:3059",
							featureProjection: "EPSG:3857",
						});
						feature.setGeometry(geom);
						zoomOnGeom();
						updateUndoRedoHistory(
							MARKER_LAYER.getSource()!
								.getFeatures()
								.map((f) => f.clone())
						);
						if (end > response.data[0].to_measure) setEnd(response.data[0].to_measure);
					} else {
						feature.setProperties(
							{
								addresses: response.data[0].addresses,
								start: response.data[0].measure,
								street: response.data[0].route_id,
								openlr: response.data[0].openlr,
							},
							true
						);
						const coord = transform([response.data[0].point.x, response.data[0].point.y], "EPSG:3059", "EPSG:3857");
						feature.setGeometry(new Point(coord));
						zoomOnGeom();
					}
					updateUndoRedoHistory(
						MARKER_LAYER.getSource()!
							.getFeatures()
							.map((f) => f.clone())
					);
					clearErrorMsg();
				})
				.catch((e) => {
					console.error(e);
					setError(createErrorMsg(e.response.data));
				});
		}
	};

	return (
		<>
			<p className="flex items-center row-span-2 justify-end">
				<span className="text-[#ff4d4f] text-sm">*</span>Lineārā reference :
			</p>
			<Select
				disabled={readOnly}
				onChange={(e) => {
					check.current = true;
					setStreet(e);
					const value = items.find((entity) => entity.routeid === e);
					if (value) {
						setPlaceholder({ min: value.min_measure, max: value.max_measure });
					}
				}}
				onBlur={() => (touched.current = { ...touched.current, street: true })}
				value={street}
				filterSort={(a, b) =>
					a.label?.charAt(0).localeCompare(b.label.charAt(0)) === 0 ? a.label.length - b.label.length : a.label?.charAt(0).localeCompare(b.label?.charAt(0))
				}
				showSearch
				options={items.map((i) => ({ value: i.routeid, label: i.routeid }))}
				className="col-span-2"
				status={error ? "error" : ""}
				id={ol.getUid(feature) + "-street"}
			></Select>
			<Input
				disabled={readOnly}
				value={start}
				onChange={(e) => {
					check.current = true;
					setStart(e.target.value);
				}}
				onBlur={() => (touched.current = { ...touched.current, start: true })}
				placeholder={placeholder.min?.toString() ?? "Sākuma punkts"}
				status={error ? "error" : ""}
				id={ol.getUid(feature) + "-start"}
			/>
			<Input
				disabled={readOnly || (geomState === "Point" && !detour)}
				value={end}
				onChange={(e) => {
					check.current = true;
					setEnd(e.target.value);
				}}
				placeholder={placeholder.max?.toString() ?? "Beigu punkts"}
				status={error ? "error" : ""}
			/>
			{error && <p className="col-start-2 col-span-2 text-[#ff4d4f]">{error}</p>}
		</>
	);
};
