import { Icon, Style } from "ol/style";
import highlightR from "../shared/img/highlight-r.png";
import highlightS from "../shared/img/highlight-s.png";

export const getPointHighlight = (square?: boolean) => {
	const style = new Style({
		image: new Icon({
			src: square ? highlightS : highlightR,
			scale: 1,
		}),
		zIndex: -Infinity,
	});
	//@ts-expect-error
	style.isHighlighted_ = true;
	return style;
};
