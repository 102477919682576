import { useState } from "react";

export default function Tag1Component(props: { text: string }) {
	const [isHovering, setIsHovering] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};
	const handleMouseOut = () => {
		setIsHovering(false);
	};

	return (
		<label
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			className=" h-[32px] p-3 bg-npp-grey-500 rounded-sm border-2 text-white border-npp-grey-500 flex justify-evenly items-center"
		>
			<p>{props.text}</p>
			{isHovering && (
				<svg width="21px" height="21px" className="invert ml-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<g>
						<path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0  c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" />
					</g>
				</svg>
			)}
		</label>
	);
}
