import { useEffect, useState } from "react";

import { useObservable } from "@ngneat/use-observable";
import { DragZoom } from "ol/interaction";
import * as condition from "ol/events/condition";

import { getMapStore } from "../Map.repository";
import { getSettingStore } from "../../settings/Settings.repository";
import { useTranslation } from "react-i18next";

export default function PanZoomControl({ handlePanZoomChange }: any) {
	const [select, setSelected] = useState(false);
	const [props] = useObservable(getMapStore());
	const [setting] = useObservable(getSettingStore());
	const [t] = useTranslation();

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	const dragZoomAlways = new DragZoom({
		condition: condition.always,
	});

	function togglePanZoom() {
		setSelected(!select);
		handlePanZoomChange(!select);
		props.map?.getInteractions().forEach((interaction) => {
			if (interaction instanceof DragZoom) {
				interaction.setActive(interaction.getActive() ? false : true);
			}
		});
	}

	useEffect(() => {
		const map = props.map;
		if (map) {
			dragZoomAlways.setActive(false);
			map.addInteraction(dragZoomAlways);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map]);

	return (
		<div
			className={classNames(setting.showPanBtn ? "" : "!sm:hidden", "ol-control right-2 z-10 top-52 sm:top-24 hidden sm:block")}
			onClick={() => {
				togglePanZoom();
			}}
			title={t("panControl") ?? ""}
		>
			<button className={"hover:bg-gray-200" + (select ? "!bg-gray-500 !text-white hover:!bg-gray-600" : "")}>P</button>
		</div>
	);
}
