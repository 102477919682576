import { StyleLayerItem } from "./Style-layer.repository";

export const DataLayersOpacitySwitcher = ({ item, onChange }: { item: StyleLayerItem; onChange: (item: StyleLayerItem) => void }) => {
	return (
		<div className="flex items-center text-npp-grey-400 space-x-1 ml-auto">
			<button
				onClick={() => {
					onChange({
						...item,
						points: item.points.map((l) => ({ ...l, opacity: l.opacity + 0.25 > 1 ? 1 : l.opacity + 0.25 })),
						lines: item.lines.map((l) => ({ ...l, opacity: l.opacity + 0.25 > 1 ? 1 : l.opacity + 0.25 })),
					});
				}}
				className="inline-flex"
			>
				<span className="material-icons !font-normal !text-sm">add</span>
			</button>
			<div className="inline-flex">
				<span className="material-icons !font-normal !text-sm">visibility</span>
			</div>
			<button
				onClick={() => {
					onChange({
						...item,
						points: item.points.map((l) => ({ ...l, opacity: l.opacity - 0.25 < 0 ? 0 : l.opacity - 0.25 })),
						lines: item.lines.map((l) => ({ ...l, opacity: l.opacity - 0.25 < 0 ? 0 : l.opacity - 0.25 })),
					});
				}}
				className="inline-flex"
			>
				<span className="material-icons !font-normal !text-sm">remove</span>
			</button>
		</div>
	);
};
