import { useContext } from "react";
import { HoverManagerContext } from "./Hover-wrapper";
import { HoverOverlayList } from "./Hover-overlay-list";
import { Feature } from "ol";
import RenderFeature from "ol/render/Feature";
import { createTitle } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { getColor, getStyle } from "../overlay/Overlay";
import { FeatureLike } from "ol/Feature";

export const HoverOverlay = () => {
	const { context } = useContext(HoverManagerContext);
	const feature = context.feature as FeatureLike;

	const getIcon = (feature: FeatureLike) => {
		if (feature.get("layer").includes("kafkamessages_cms")) {
			const temp = feature.get("t1");
			return feature.get("status") === "active" ? (
				<div className="bg-[#4b4d4f] p-6 text-white rounded-full relative shadow-md mt-0.5">
					<div className="absolute inset-0 flex items-center justify-center font-semibold">
						<p>{temp ? `${temp > 0 ? "+" : ""}${Number(temp).toFixed(0)}°` : ""}</p>
					</div>
				</div>
			) : (
				<div className="bg-[#c1c2c2] p-6 text-white rounded-full relative shadow-md mt-0.5">
					<div className="absolute inset-0 flex items-center justify-center font-semibold">
						<p>-</p>
					</div>
				</div>
			);
		}

		if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
			const style = getStyle(feature, feature.get("internal"));
			return style["icon"];
		}

		return <img src={feature.get("internal")["icon"]} alt="feature_icon"></img>;
	};

	const getTitle = () => {
		if (!feature) return "";
		const lr = feature.get("linear_reference");

		if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
			const style = getStyle(feature, (feature as any).get("internal"));

			return style?.popup_title ? createTitle(feature, style.popup_title) : style ? style.name + " " + (lr ?? "") : "";
		} else if (feature instanceof Feature) {
			const internal = feature.get("internal");
			return internal["title"] ?? (internal?.["styleName"] ?? internal?.["layerName"]) + " " + (lr ?? "");
		}
		return "";
	};

	const getProps = (feature: Feature) => {
		const props = feature.getProperties();
		return props;
	};

	const getLineIcon = (feature: Feature | FeatureLike, i: number, style: any | undefined) => {
		switch (style?.stroke_style) {
			case "contour":
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full relative`}>
						{
							<div className="h-12 w-12 flex flex-col gap-1 justify-center">
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
							</div>
						}
					</button>
				);
			case "dot":
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full`}>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex items-center justify-center gap-1">
									{[1, 2, 3, 4, 5].map((i) => (
										<div
											key={i}
											style={{
												background: style.color ?? getColor(feature),
											}}
											className="h-2 w-2 rounded-full shrink-0"
										></div>
									))}
								</div>
							}
						</div>
					</button>
				);
			default:
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full`}>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex flex-col justify-center">
									<div
										style={{
											background: style?.color ?? getColor(feature),
										}}
										className="w-full h-1.5 rounded-full"
									></div>
								</div>
							}
						</div>
					</button>
				);
		}
	};

	return (
		<section id="hoverlay" className="p-4 rounded w-[30em] border-2 border-npp-grey-500 bg-white flex z-50 shadow-md mt-0.5">
			<div className="flex flex-col gap-1">
				{(feature?.getGeometry()?.getType() === "LineString"
					? getLineIcon(feature, 0, getStyle(feature, feature.get("internal")))
					: feature && (feature.get("internal")["icon"] || feature.get("layer").includes("kafkamessages_cms")) && <div>{getIcon(feature)}</div>) ||
					(feature instanceof RenderFeature && (
						<div>
							<img src={getStyle(feature, feature.get("internal"))?.["piktogram"]} alt="feature_icon" />
						</div>
					))}
			</div>
			<div className="flex-grow mx-4">
				<div className="flex mb-4 min-w-0 wrap items-start">
					<p className="text-lg font-semibold leading-tight text-npp-grey-500">{getTitle()}</p>
				</div>
				{feature && <HoverOverlayList props={getProps(feature as any)} feature={feature}></HoverOverlayList>}
			</div>
		</section>
	);
};
