import { useState } from "react";
import Checkbox1Component from "../../shared/UI kit/checkbox-1";
import { StyleLayerItem } from "../Style-layer.repository";
import { t } from "i18next";
import { DataLayersOpacitySwitcher } from "../Data-layers-opacity-switcher";

export const LayerSwitcherItem = ({ item, onChange, zoom }: { item: StyleLayerItem; onChange: any; zoom: number }) => {
	const [checked, setChecked] = useState(item.visible);
	const styleDef = (item.lines.length > 0 ? item.lines : item.points)?.[0];
	const inactive = styleDef ? zoom > styleDef.maxZoom! + 1 || zoom < styleDef.minZoom! + 1 : true;

	return (
		<li className="text-sm flex gap-[0.6rem] items-center ml-14">
			<Checkbox1Component
				label={<span className="text-black/70">{item.name ?? ""}</span>}
				id={"sub-check-" + item.id}
				checked={checked}
				onChange={() => {
					setChecked(!checked);
					onChange({ ...item, visible: !checked });
				}}
				title={inactive ? t("outsideZoom") ?? "" : ""}
				name=""
				inactive={inactive}
			></Checkbox1Component>
			<DataLayersOpacitySwitcher item={item} onChange={(i: StyleLayerItem) => onChange(i)}></DataLayersOpacitySwitcher>
		</li>
	);
};
