import i18next from "i18next";
import { useState } from "react";

export let CURRENT_LANGUAGE = "lv";

export const LanguageSwitcher = () => {
  const [value, setValue] = useState("lv");

  const toggleLanguage = () => {
    CURRENT_LANGUAGE = value === "lv" ? "en" : "lv";
    i18next.changeLanguage(CURRENT_LANGUAGE);
    setValue(CURRENT_LANGUAGE);
  };

  return (
    <div className={"ol-control z-10 top-36 absolute right-2"}>
      <button
        onClick={() => toggleLanguage()}
        className={"hover:bg-gray-200 uppercase text-sm"}
        title="TESTA_NOLŪKIEM"
      >
        {value}
      </button>
    </div>
  );
};
