import { useObservable } from "@ngneat/use-observable";

import Button1Component from "../shared/UI kit/button-1";
import Checkbox1Component from "../shared/UI kit/checkbox-1";
import Radio1Component from "../shared/UI kit/radio-1";
import { getSettingStore, updateSettingStore } from "./Settings.repository";
import { getMapStore } from "../map/Map.repository";
import { useEffect, useState } from "react";
import { SettingsChangeRemoteLayer } from "./Settings-change-remote-layer";
import { Map } from "ol";
import LayerGroup from "ol/layer/Group";
import BaseLayer from "ol/layer/Base";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { useTranslation } from "react-i18next";
import { Layer } from "ol/layer";

function Settings() {
	const [layers, setLayers] = useState<Layer[]>([]);
	const [opacity, setOpacity] = useState(0);
	const [settingsStore] = useObservable(getSettingStore());
	const [mapStore] = useObservable(getMapStore());

	const [t] = useTranslation();

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	const getBaseLayers = (map: Map) => {
		return map.getAllLayers().filter((l) => !(l instanceof LayerGroup) && l.get("type") === "base");
	};

	useEffect(() => {
		if (!mapStore.map) return;

		setLayers(getBaseLayers(mapStore.map));
		setCurrentBaseLayerOpacity();
		mapStore.map.on("change", () => {
			setLayers(getBaseLayers(mapStore.map!));
			setCurrentBaseLayerOpacity();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapStore.map]);

	function handleChangeBaseLayer(event: any) {
		mapStore.map
			?.getAllLayers()
			.filter((layer) => layer.getProperties().type === "base")
			.map((layer) => layer.setVisible(layer.getProperties().id === event.target.id));
		mapStore.map?.changed();
	}

	useEffect(() => {
		if (mapStore.map && (settingsStore.baseOpacity || settingsStore.baseOpacity === 0)) {
			mapStore.map
				?.getAllLayers()
				.filter((layer) => layer.getProperties().type === "base" && layer.getVisible())
				.map((layer) => {
					if (settingsStore.showBaseOpacity) {
						layer.setOpacity(settingsStore.baseOpacity === 0 ? 0 : (settingsStore.baseOpacity || 100) / 100);
					} else {
						layer.setOpacity(1);
					}
					return layer;
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settingsStore.baseOpacity]);

	const setCurrentBaseLayerOpacity = () => {
		const layer = (
			mapStore
				.map!.getLayers()
				.getArray()
				.find((l) => l.get("type") === "base") as BaseLayer
		)
			?.getLayersArray()
			.find((l) => l.getVisible());
		setOpacity(100 - (layer?.getOpacity() || 1) * 100);
	};

	const renderBaseLayers = (layers: any) => {
		return layers?.map((layer: any) => (
			<Radio1Component
				key={layer.get("id")}
				label={layer.get("title")}
				id={layer.get("id")}
				checked={layer.getVisible()}
				name="base"
				onChange={(event: any) => handleChangeBaseLayer(event)}
			></Radio1Component>
		));
	};

	return (
		<div className={classNames(!settingsStore.open ? "hidden" : "", "fixed inset-0 z-50 overflow-y-auto bg-opacity-60 bg-black/40")}>
			<div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0 ">
				<div className="bg-white text-npp-grey-500 border-2 border-neutral-700 p-14 rounded absolute pointer-events-auto shadow-lg">
					<div className="flex items-start justify-between">
						<h2 className="text-4xl font-semibold mb-12 text-left">{t("mapSettings")}</h2>
						<button
							type="button"
							className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-npp-blue-500 focus:ring-offset-2"
							onClick={() => updateSettingStore({ open: false })}
						>
							<CloseOutlined style={{ fontSize: "32px" }} rev={undefined} />
						</button>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[5.5rem] gap-y-16 mb-12">
						<fieldset className="gap-5 flex flex-col text-left">
							<legend className="text-3xl font-medium mb-12">{t("basemap")}</legend>
							{renderBaseLayers(layers)}
						</fieldset>
						<SettingsChangeRemoteLayer></SettingsChangeRemoteLayer>
						<fieldset className="gap-5 flex flex-col">
							<legend className="text-3xl font-medium mb-12 text-left">{t("otherSettings")}</legend>
							<Checkbox1Component
								label={t("showLayerSource")}
								id={"radit"}
								name="citi"
								checked={JSON.parse(localStorage.getItem("setting") as string)?.showAttributionBtn}
								onChange={() =>
									updateSettingStore({
										showAttributionBtn: !JSON.parse(localStorage.getItem("setting") as string)?.showAttributionBtn,
									})
								}
							></Checkbox1Component>
							<div className="flex gap-2 items-center text-left">
								<Checkbox1Component
									label={t(["baseOpacity"])}
									id={"pamatkartes"}
									name="citi"
									checked={JSON.parse(localStorage.getItem("setting") as string)?.showBaseOpacity}
									onChange={() =>
										updateSettingStore({
											showBaseOpacity: !JSON.parse(localStorage.getItem("setting") as string)?.showBaseOpacity,
										})
									}
								></Checkbox1Component>
								<div className="relative h-6">
									<input
										value={opacity}
										type="number"
										min="0"
										max="100"
										name="opacity"
										id="opacity"
										className="py-1 bg-white rounded-sm border-2 border-npp-grey-500 flex justify-evenly items-center text-npp-grey-500 pl-2 disabled:text-npp-grey-400 disabled:border-npp-grey-400"
										placeholder="100"
										onChange={(event) => {
											setOpacity(Number(event.target.value));
											updateSettingStore({
												baseOpacity: 100 - parseInt(event.target.value),
											});
										}}
										disabled={JSON.parse(localStorage.getItem("setting") as string)?.showBaseOpacity === false}
									/>
									<div className="pointer-events-none absolute inset-y-0 top-3 flex items-center pl-10">
										<span className="text-gray-500 sm:text-sm">%</span>
									</div>
								</div>
							</div>
							<Checkbox1Component
								label={t("showLegend")}
								id={"legendu"}
								name="citi"
								checked={JSON.parse(localStorage.getItem("setting") as string)?.showLegendBtn}
								onChange={() =>
									updateSettingStore({
										showLegendBtn: !JSON.parse(localStorage.getItem("setting") as string)?.showLegendBtn,
									})
								}
							></Checkbox1Component>
							<Checkbox1Component
								label={t("showPanning")}
								id={"raditpan"}
								name="citi"
								checked={JSON.parse(localStorage.getItem("setting") as string)?.showPanBtn}
								onChange={() =>
									updateSettingStore({
										showPanBtn: !JSON.parse(localStorage.getItem("setting") as string)?.showPanBtn,
									})
								}
							></Checkbox1Component>
						</fieldset>
					</div>
					<Button1Component text={t("save")} onClick={() => updateSettingStore({ open: false })}></Button1Component>
				</div>
			</div>
		</div>
	);
}

export default Settings;
