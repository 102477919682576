export const MapMarkerOpenTab = () => {
  return (
    <a
      href={window.location.origin + "?edit=10"}
      target="_blank"
      rel="noreferrer"
      className="text-[#1677FF] text-sm underline"
    >
      Atvērt karti jaunā cilnē
    </a>
  );
};
