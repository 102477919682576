import { useState } from "react";
import bg from "../switch-bg.png";
import btn from "../switch-btn.png";
import btn2 from "../switch-btn-off.png";

export const CustomSwitcher = (props: { value: boolean; onChange: (value: boolean) => void }) => {
	const [v, setV] = useState(props.value);

	const CSS = `.catalog-main-filter-option-checkbox {
	display: flex;
	justify-content: center;
	align-items: center;
}

.catalog-main-filter-option-checkbox-switcher {
	width: 68px;
	height: 30px;
	position: relative;
	background-position: center;
	background-size: cover;
	border-radius: 30px;
	display: flex;
	transition: ease-in-out 0.3s;
	background-image: url(${bg});
}

.catalog-main-filter-option-checkbox-btn {
	margin-top: 4px;
	margin-left: 42px;
	width: 22px;
	height: 22px;
	transition: ease-in-out 0.3s;
}

.catalog-main-filter-option-checkbox-checkF {
    background-image: url(${bg});
    text-align: right;
}

.catalog-main-filter-option-checkbox-checkT {
    background-size: cover;
    margin-left: 4px;
}`;

	return (
		<div className="catalog-main-filter-option-checkbox cursor-pointer select-none">
			<style>{CSS}</style>
			<div
				onClick={() => {
					setV(!v);
					props.onChange(!v);
				}}
				className={`catalog-main-filter-option-checkbox-switcher ${!v ? "catalog-main-filter-option-checkbox-checkF" : ""}`}
			>
				<div
					style={{ backgroundImage: v ? `url(${btn})` : `url(${btn2})` }}
					className={`catalog-main-filter-option-checkbox-btn ${!v ? "catalog-main-filter-option-checkbox-checkT" : ""}`}
				></div>
			</div>
		</div>
	);
};
