import { createContext, useState } from "react";
import Select from "./Select";
import { Overlay } from "./Overlay";
import { Feature } from "ol";

export interface OverlayManagerData {
	features: Feature[];
}

export const OverlayManagerContext = createContext<{
	context: OverlayManagerData;
	setContext: React.Dispatch<React.SetStateAction<OverlayManagerData>>;
}>({} as any);

export const OverlayWrapper = () => {
	const [context, setContext] = useState({
		features: [],
	} as OverlayManagerData);

	return (
		<OverlayManagerContext.Provider value={{ context, setContext }}>
			<Overlay></Overlay>
			<Select></Select>
		</OverlayManagerContext.Provider>
	);
};
