import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../map/Map.repository";
import { useEffect } from "react";
import LayerGroup from "ol/layer/Group";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { AltLayerEntity, GroupEntity } from "../store/Layers.repository";
import { dataLayerStyle, doDarkMagic, strToFilter } from "./Style-generator";
import VectorSource from "ol/source/Vector";
import { Feature, Map, VectorTile } from "ol";
import { Point } from "ol/geom";
import { IS_LOCALHOST, LAYERPOINT } from "../map/Endpoints";
import { Layer } from "ol/layer";
import { take } from "rxjs";
import VectorImageLayer from "ol/layer/VectorImage";

export let LAYERGROUPS: LayerGroup[] = []; //List of LayerGroups for Data-layer switcher

export const getLayerID = (url: string) => {
	const id = url.split("/");
	return `${id[id.length - 6]}_${id[id.length - 5]}`;
};

export const UVIS_LAYER_CACHE: { [id: string]: Feature[] } = {};

export const UVIS_SOURCE = new VectorSource();

export const createUvisSource = ({ item, id, refresh }: { item: AltLayerEntity; id: string; refresh?: boolean }) => {
	const source = new VectorTileSource({
		transition: 0,
		url: IS_LOCALHOST
			? `${LAYERPOINT}/layer/${item.table.replace("_", "/")}/tile/{z}/{x}/{y}${item.data_api_filter ? "?filter=" + item.data_api_filter : ""}`
			: item.mvt_url,
		projection: "EPSG:3857",
		format: new MVT({
			featureClass: Feature as any,
		}),
		attributions: item.attributions,
		wrapX: false,
		cacheSize: 16,
		overlaps: false,
	});
	source.set("refresh", !!refresh);
	source.on("tileloadend", (e) => {
		getMapStore()
			.pipe(take(1))
			.subscribe((mapStore) => {
				const map = mapStore.map!;
				const zoom = map.getView().getZoom()!;
				const features = (e.tile as VectorTile<Feature>).getFeatures().filter((f) => {
					if (
						f.getGeometry()?.getType() === "Point" &&
						item.points.find((p) => !p.filter || doDarkMagic(f, { filter: strToFilter(p.filter), style: true as any, styleDef: undefined as any })) //override typedefs to reuse code
					) {
						(f as Feature).set("id", id + "-" + f.get("id"), true);
						return true;
					}
					return false;
				}) as Feature[];
				const newFeatures = features.filter((f) => {
					const ids = UVIS_LAYER_CACHE[id]?.map((feature: Feature) => feature.get("id")) ?? [];

					if (f.getGeometry() instanceof Point) {
						if (ids.indexOf(f.get("id")) === -1) {
							f.set("zoom", zoom, true);
							return true;
						}

						const cacheFeature = UVIS_LAYER_CACHE[id][ids.indexOf(f.get("id"))];

						if (source.get("refresh") && !cacheFeature?.get("justUpdated")) {
							UVIS_SOURCE.removeFeature(cacheFeature);
							UVIS_LAYER_CACHE[id].splice(ids.indexOf(f.get("id")), 1);
							f.set("zoom", zoom, true);
							f.setProperties({ zoom: zoom, justUpdated: true });
							return true;
						} else {
							cacheFeature.set("justUpdated", false);
						}

						if (zoom > cacheFeature?.get("zoom")) {
							cacheFeature.set("zoom", zoom, true);
							const geom = cacheFeature.getGeometry() as Point;
							//@ts-expect-error override flatCoordinates
							geom.flatCoordinates = (f.getGeometry() as Point).getFlatCoordinates();
						}
						return false;
					}
					return false;
				});
				UVIS_SOURCE.addFeatures(newFeatures);
				UVIS_SOURCE.changed();

				if (newFeatures.length > 0) {
					UVIS_LAYER_CACHE[id] = [...newFeatures, ...(UVIS_LAYER_CACHE[id] ?? [])];
				}
			});
	});
	source.setProperties({ params: { item, id } });
	return source;
};

export const addUvisGroup = (group: GroupEntity, map: Map, layerList?: string[]) => {
	const layerGroup = new LayerGroup({
		visible: layerList ? layerList.includes(group.name + "-" + group.id) : !!(group.layers as any).find((l: AltLayerEntity) => l.enabled),
		properties: {
			name: group.name,
			id: group.name + "-" + group.id,
			type: "uvis",
			attributions: group.attributions,
			dataSource: group.dataSource,
		},
		layers: (group.layers as any)
			.filter((l: AltLayerEntity) => l.table === "kafkamessages_uvis")
			.map((l: AltLayerEntity, i: number) => {
				const id = l.name + "-" + l.id;
				const layer = new VectorTileLayer({
					opacity: (100 - l.opacity) / 100,
					zIndex: -10,
					renderMode: "vector",
					renderOrder: null as unknown as any, //Wrong typedef workaround, null disables ordering
					declutter: true,
					renderBuffer: 0,
					minZoom: l.zoom_from,
					maxZoom: l.zoom_to,
					properties: {
						name: l.name,
						id,
						table: l.table,
						legend: {
							points: l.points,
							lines: l.lines,
						},
						attributions: l.attributions,
						clustered: true,
					},
					source: createUvisSource({ item: l, id: id }),
					visible: layerList ? layerList.includes(l.name + "-" + l.id) : l.enabled,
				});

				const style = dataLayerStyle({
					lines: l.lines,
					points: l.points,
					params: {
						attributes: l.attributes,
						title: l.label,
						titleFont: l.label_style,
						table: l.table,
						layer,
						use_uvis_popups: group.use_uvis_popups,
						layerName: l.name,
					},
				});

				layer.setProperties({ style: style });
				layer.setStyle(style);

				layer.on("change:visible", (e) => {
					layer.setOpacity(1);
					const cache = UVIS_LAYER_CACHE[layer.get("id")];
					if (e.oldValue && cache) {
						removeFeatures(cache);
					} else if (layerGroup.getVisible() && cache) {
						UVIS_SOURCE.addFeatures(cache);
					}
				});

				map.getView().on("change", () => {
					const zoom = map.getView().getZoom()!;
					const cache = UVIS_LAYER_CACHE[layer.get("id")];
					if (layer.getVisible() && layerGroup.getVisible() && (zoom <= l.zoom_from || zoom >= l.zoom_to) && cache) {
						removeFeatures(cache);
						layer.set("range", false, true);
					} else if (layer.getVisible() && layerGroup.getVisible() && layer.get("range") === false && cache) {
						try {
							UVIS_SOURCE.addFeatures(cache);
						} catch {}
						layer.set("range", true, true);
					}
				});

				return layer;
			}),
	});

	layerGroup.on("change:visible", (e) => {
		const layers = layerGroup.getLayersArray().filter((l: Layer) => l.getVisible());
		if (layers.length > 0) {
			layers.forEach((l: Layer) => {
				const cache = UVIS_LAYER_CACHE[l.get("id")];
				if (e.oldValue && cache) {
					removeFeatures(cache);
				} else if (cache) {
					UVIS_SOURCE.addFeatures(cache);
				}
			});
		}
	});
	map.addLayer(layerGroup);
	return layerGroup;
};

const removeFeatures = (cache: Feature[]) => {
	const features = UVIS_SOURCE.getFeatures().filter((f) => !cache.find((c) => f.get("id") === c.get("id")));
	UVIS_SOURCE.clear();
	UVIS_SOURCE.addFeatures(features);
};

export default function DataLayersUvis() {
	const [mapStore] = useObservable(getMapStore());

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		const layer = new VectorImageLayer({
			zIndex: -2,
			maxZoom: 30,
			properties: {
				name: "Uvis",
				id: "uvis",
			},
			source: UVIS_SOURCE,
			style: (feature) => {
				return feature.get("internal")?.["style"];
			},
		});

		map.addLayer(layer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapStore]);

	return <></>;
}
