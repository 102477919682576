export default function Checkbox1Component(props: { label: any; id: string; name: string; checked?: boolean; onChange?: any; inactive?: boolean; title?: string }) {
	return (
		<div className="flex items-center select-none gap-4" title={props.title ?? ""}>
			<div className="relative flex items-center">
				<input
					className="peer/published border-npp-grey-300 border-2 rounded-sm w-[20px] h-[20px] appearance-none focus:outline-transparent hover:outline hover:outline-npp-blue-500 hover:outline-2 hover:opacity-80 bg-white cursor-pointer"
					type="checkbox"
					id={props.id}
					name={props.name}
					checked={props.checked}
					onChange={props.onChange}
				/>
				{props.checked && (
					<div
						className={`absolute pointer-events-none left-[5px] top-[5px] rounded-sm ${props.inactive ? "bg-npp-grey-400" : "bg-npp-blue-500"}  w-[10px] h-[10px]`}
					></div>
				)}
			</div>

			<label
				className={`peer-checked/published:text-npp-grey-500 text-base text-npp-grey-400 cursor-pointer ${props.checked && "text-npp-grey-500"}`}
				htmlFor={props.id}
			>
				{props.label}
			</label>
		</div>
	);
}
