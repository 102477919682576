import { Feature, Map, getUid } from "ol";
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import { Circle, Stroke, Style } from "ol/style";
import { useEffect, useRef } from "react";
import GeoJSON from "ol/format/GeoJSON.js";
import convex from "@turf/convex";
import { MultiPoint, Point } from "ol/geom";
import area from "@turf/area";
import LayerGroup from "ol/layer/Group";

export const highlightStyle = new Style({
	stroke: new Stroke({
		color: "black",
		lineDash: [1, 16],
		lineJoin: "bevel",
		lineCap: "butt",
	}),
	image: new Circle({
		stroke: new Stroke({
			color: "black",
			lineDash: [1, 4],
			lineJoin: "bevel",
			lineCap: "butt",
		}),
		radius: 8,
	}),
	zIndex: -Infinity,
});

const CLUSTER_HIGHLIGHT_FEATURE = new Feature({
	style: highlightStyle,
});

export const CoolerClusterHighlight = ({ map }: { map: Map }) => {
	const uid = useRef("");

	useEffect(() => {
		if (!map) return;

		const layer = new VectorImageLayer({ zIndex: 1, source: new VectorSource({ features: [CLUSTER_HIGHLIGHT_FEATURE] }) });
		const reader = new GeoJSON();
		layer.setMap(map);

		const yekEvent = map.on("movestart", () => {
			CLUSTER_HIGHLIGHT_FEATURE.setGeometry(undefined);
		});

		const keyEvent = map.on("pointermove", (e) => {
			const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 4 });
			if (features.length > 0) {
				const lg = map
					.getLayers()
					.getArray()
					.find((lg) => lg instanceof LayerGroup && lg.get("type") === "base");
				const overview = lg?.getLayersArray().find((l) => l.getZIndex() === 100);
				if (!overview) document.body.style.cursor = "pointer";
				features.forEach((f) => {
					const feats = f.get("features");
					if (feats && uid.current !== getUid(f)) {
						uid.current = getUid(f);
						if (feats.length < 4) {
							const multi = new MultiPoint(feats.map((f: Feature) => (f.getGeometry() as Point).getCoordinates()));
							CLUSTER_HIGHLIGHT_FEATURE.setGeometry(multi);
							CLUSTER_HIGHLIGHT_FEATURE.setStyle(highlightStyle);
						} else {
							const points = reader.writeFeaturesObject(feats, { dataProjection: "EPSG:4326", featureProjection: "EPSG:3857" });
							const hull = convex(points as any);
							if (hull) {
								const size = area(hull);
								if (size / map.getView().getResolution()! < 30000) {
									const multi = new MultiPoint(feats.map((f: Feature) => (f.getGeometry() as Point).getCoordinates()));
									CLUSTER_HIGHLIGHT_FEATURE.setGeometry(multi);
									CLUSTER_HIGHLIGHT_FEATURE.setStyle(highlightStyle);
								} else {
									//@ts-expect-error
									CLUSTER_HIGHLIGHT_FEATURE.setGeometry(reader.readFeature(hull, { dataProjection: "EPSG:4326", featureProjection: "EPSG:3857" }).getGeometry()!);
									CLUSTER_HIGHLIGHT_FEATURE.setStyle(highlightStyle);
								}
							}
						}
					}
				});
			} else {
				document.body.style.cursor = "auto";
				CLUSTER_HIGHLIGHT_FEATURE.setGeometry(undefined);
				uid.current = "";
			}
		});

		return () => {
			map.un("pointermove", keyEvent.listener);
			map.un("movestart", yekEvent.listener);
		};
	}, [map]);

	return <></>;
};
