import { createStore } from "@ngneat/elf";
import { deleteAllEntities, selectAllEntities, upsertEntities, withEntities } from "@ngneat/elf-entities";
import { LineStyle, PointStyle } from "../store/Layers.repository";

export interface StyleLayerItem {
	id: string;
	name: string;
	visible: boolean;
	table: string;
	lines: LineStyle[];
	points: PointStyle[];
	clustered: boolean;
	index: number;
	attributions: string;
	originalTable?: string;
}

export interface StyleLayerProp {
	id: string;
	name: string;
	layers: StyleLayerItem[];
	visible: boolean;
	dataSource?: string;
}

const STORE = createStore({ name: "styleLayer" }, withEntities<StyleLayerProp>({}));

export function getStyleItems() {
	return STORE.pipe(selectAllEntities());
}

export const updateStyleItems = (results: StyleLayerProp[]) => {
	STORE.update(upsertEntities(results));
};

export const clearStyleItems = () => {
	STORE.update(deleteAllEntities());
};
