export default function Button1Component(props: { text: string; onClick?: () => void; icon?: boolean | any; class?: string }) {
	return (
		<button
			className={
				props.class ??
				"px-8 py-4 bg-npp-blue-500 rounded-7 shadow-npp-blue-sm flex justify-evenly items-center hover:bg-npp-blue-600 active:border-none active:bg-npp-blue-500 active:shadow-npp-blue-lg"
			}
			type="button"
			onClick={props.onClick}
		>
			{props.icon === true ? (
				<svg className="w-[17px] h-[17px] invert" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 5V19M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			) : props.icon ? (
				props.icon
			) : (
				<></>
			)}
			<p className="text-white text-lg font-montserrat font-medium">{props.text}</p>
		</button>
	);
}
