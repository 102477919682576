import { useObservable } from "@ngneat/use-observable";
import { useEffect, useState } from "react";
import { getMapStore } from "../map/Map.repository";
import { Map } from "ol";
import BaseLayer from "ol/layer/Base";
import Checkbox1Component from "../shared/UI kit/checkbox-1";

export const SettingsChangeRemoteLayer = () => {
	const [groups, setGroups] = useState<BaseLayer[]>([]);
	const [mapStore] = useObservable(getMapStore());

	const getRemoteLayerGroups = (map: Map) => {
		return map
			.getLayers()
			.getArray()
			.filter((l) => l.get("type") === "remote");
	};

	useEffect(() => {
		const map = mapStore.map;
		if (!map) return;

		setGroups(getRemoteLayerGroups(map));
		map.on("change", () => {
			setGroups(getRemoteLayerGroups(map));
		});
	}, [mapStore]);

	function toggleVisibility(layer: any) {
		layer.setVisible(!layer.getVisible());
		mapStore.map?.changed();
	}

	return groups.length ? (
		<fieldset className="gap-4 flex flex-col text-left">
			<legend className="text-3xl font-medium mb-8">Slāņu objekti</legend>
			{groups.map((g: any) => (
				<div key={g.get("id")}>
					<span className="font-semibold">{g.get("name")}</span>
					<div className="mt-2">
						{g
							.getLayers()
							.getArray()
							.map((l: any) => (
								<Checkbox1Component
									key={l.get("id")}
									label={<span className="">{l.get("title")}</span>}
									id={l.get("id")}
									checked={l.getVisible()}
									name={l.get("title")}
									onChange={() => toggleVisibility(l)}
								></Checkbox1Component>
							))}
					</div>
				</div>
			))}
		</fieldset>
	) : (
		<></>
	);
};
