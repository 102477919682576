import VectorSource from "ol/source/Vector";
import { getMapStore } from "../map/Map.repository";
import { useObservable } from "@ngneat/use-observable";
import { SimpleGeometry } from "ol/geom";
import { useEffect } from "react";
import { SearchResultObject } from "../map/Feature";

export const SearchResultItem = ({ item, onClick, active }: { item: SearchResultObject; onClick: () => void; active: boolean }) => {
	const [mapStore] = useObservable(getMapStore());

	const addResultFeature = (val: boolean) => {
		const map = mapStore.map;
		const layer = mapStore.map?.getAllLayers().find((l) => l.get("id") === "search");

		if (map && layer) {
			const source = layer.getSource() as VectorSource;
			if (val) {
				source.addFeature(item.feature);
				layer.changed();

				mapStore.map?.getView().fit(item.feature.getGeometry() as SimpleGeometry, {
					duration: 500,
					maxZoom: mapStore.map.getView().getZoom()! > 16 ? mapStore.map.getView().getZoom() : 16,
				});
			} else {
				source.removeFeature(item.feature);
			}
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => addResultFeature(false), []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => addResultFeature(active), [active]);

	return (
		<li
			onClick={() => {
				onClick();
			}}
			className={`p-4 hover:bg-npp-grey-300/50 cursor-pointer ${active ? "bg-npp-grey-300/50" : ""}`}
		>
			{item.display_name}
		</li>
	);
};
