export default function Button2Component(props: { text: string }) {
	return (
		<button
			className="w-[239px] h-[70px] bg-white rounded-7 border-npp-grey-500
      border shadow-npp-grey-sm flex justify-evenly items-center hover:outline-[#00ccf5] hover:outline hover:outline-4 hover:opacity-80 "
			type="button"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 42.67" className="w-[27px] h-[#18px]">
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M63.22,19.57,44.5.78a2.67,2.67,0,0,0-3.78,3.77L54.79,18.67H2.67a2.67,2.67,0,1,0,0,5.33H55L40.73,38.11a2.67,2.67,0,1,0,3.76,3.78L63.21,23.35a2.67,2.67,0,0,0,0-3.78Z" />
					</g>
				</g>
			</svg>
			<p className="text-npp-grey-500 text-2xl font-montserrat font-medium">{props.text}</p>
		</button>
	);
}
