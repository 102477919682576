import { useObservable } from "@ngneat/use-observable";
import { StyleLayerProp } from "../Style-layer.repository";
import { LayerSwitcherExpander } from "./Layer-switcher-expander";
import { getMapStore } from "../../map/Map.repository";
import LayerGroup from "ol/layer/Group";
import { createMapboxStyle } from "../Mapbox-style-generator";
import { stylefunction } from "ol-mapbox-style";
import spriteJson from "../../shared/sprites/icons.json";
import iconspng from "../../shared/sprites/icons.png";
import { getMVTStyles } from "../Mapbox-helper";
import VectorTileLayer from "ol/layer/VectorTile";
import { OVERLAY_MANAGER } from "../../map/overlay/Select";
import { COOLER_CLUSTER_SOURCE } from "./Cluster/Cooler-cluster";
import { COOLER_LAYER_CACHE, createTitle, findStyle } from "./Cluster/Cooler-cluster-group";
import { doDarkMagic, strToFilter } from "../Style-generator";

export const LayerSwitcherLayerList = (props: { items: StyleLayerProp[]; expand: boolean; styleLayers: StyleLayerProp[] }) => {
	const [mapStore] = useObservable(getMapStore());

	const groupChange = (group: StyleLayerProp) => {
		OVERLAY_MANAGER.setPosition(undefined);

		const layerArray = mapStore.map?.getLayerGroup().getLayers().getArray();
		const tables = Array.from(new Set(group.layers.map((l) => l.table)));
		const lg = layerArray?.find((l) => l.get("type") === "data") as LayerGroup;
		const layers = lg.getLayersArray().filter((l) => tables.includes(l.get("id")));

		layers.forEach((l) => {
			const items = [...props.styleLayers.filter((sl) => sl.id !== group.id), group]
				.filter((g) => g.visible)
				.map((g) => g.layers)
				.flat()
				.filter((styleLayer) => styleLayer.visible && styleLayer.table === l.get("id"));

			const names = props.styleLayers.map((sl) => sl.name);
			items.sort((a, b) => {
				const g1 = props.styleLayers.find((sl) => !!sl.layers.find((layer) => layer.name === a.name))!.name;
				const g2 = props.styleLayers.find((sl) => !!sl.layers.find((layer) => layer.name === b.name))!.name;
				return names.indexOf(g2) === names.indexOf(g1) ? (a.index > b.index ? -1 : 1) : names.indexOf(g2) - names.indexOf(g1);
			});
			const lines = items.map((i) => i.lines).flat();
			const points = items.map((i) => (i.clustered ? i.points.map((p) => ({ ...p, clustered: true })) : i.points)).flat();

			if (lines.length > 0 || points.length > 0) {
				const allItems = l.get("layers");
				const [allLines, allPoints] = getMVTStyles(allItems);
				const mapboxStyle = createMapboxStyle(lines, points, l.get("url"), allLines, allPoints, l.get("use_uvis_popups"));
				stylefunction(l as VectorTileLayer, mapboxStyle, "features", undefined, spriteJson, iconspng);
				l.setVisible(true);
			} else {
				l.setVisible(false);
			}
		});

		const clg = layerArray?.find((l) => l instanceof LayerGroup && l.get("type") === "cluster");
		if (clg) {
			const clayers = clg?.getLayersArray().filter((l) => tables.includes(l.get("id")));
			clayers?.forEach((cl) => {
				const items = [...props.styleLayers.filter((sl) => sl.id !== group.id), group]
					.filter((g) => g.visible)
					.map((g) => g.layers)
					.flat()
					.filter((styleLayer) => styleLayer.visible && styleLayer.table === cl.get("id"));
				const points = items.map((i) => i.points).flat();

				if (points.length > 0) {
					const tables = Array.from(new Set(items.map((l) => l.table)));
					tables.forEach((t) => {
						COOLER_LAYER_CACHE[t]?.forEach((feature) => {
							const internal = feature.get("internal");
							if (tables.includes(internal["table"])) {
								const styleDef = points.find(
									(p) =>
										feature.get("layer") === p.source &&
										(!p.filter || doDarkMagic(feature, { filter: strToFilter(p.filter), style: true as any, styleDef: undefined as any }))
								);
								const style = styleDef ? findStyle({ table: internal["originalTable"] ?? internal["table"] }, feature, styleDef) : styleDef;
								feature.set(
									"internal",
									{
										...feature.get("internal"),
										style: style ? (Array.isArray(style) ? style : [style]) : style,
										icon: styleDef?.piktogram,
										styleName: styleDef?.name,
										title: styleDef?.popup_title ? createTitle(feature, styleDef.popup_title) : undefined,
									},
									true
								);
							}
						});
					});
				}

				cl.setVisible(points.length > 0);
			});
			COOLER_CLUSTER_SOURCE.changed();
		}

		/* const uvisLayers = group.layers.filter((l) => [l.table, l.originalTable].includes("kafkamessages_uvis"));
		if (uvisLayers.length > 0) {
			const lg = mapStore.map
				?.getLayers()
				.getArray()
				.find((l) => l.get("id") === group.id)!;
			lg.setVisible(group.visible);
			uvisLayers.forEach((cl) => {
				lg.getLayersArray()
					.find((l) => l.get("id") === cl.id)
					?.setVisible(cl.visible);
			});
		} */
	};

	return (
		<ul className="divide-y-2 divide-neutral-200/70 overflow-y-auto scroll-bar mt-4 pr-4 pb-1">
			{props.items.map((item) => (
				<LayerSwitcherExpander onGroupChange={(g: StyleLayerProp) => groupChange(g)} group={item} key={item.id} forceExpand={props.expand}></LayerSwitcherExpander>
			))}
		</ul>
	);
};
