import { useObservable } from "@ngneat/use-observable";
import { LayerEntity, getGroupEntities } from "../store/Layers.repository";
import { useEffect, useState } from "react";
import { getMapStore } from "./Map.repository";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from "ol/layer/Group";
import { map } from "rxjs";
import { useSearchParams } from "react-router-dom";
import { Map } from "mapbox-gl";
import Layer from "ol/layer/Layer.js";
import Source from "ol/source/Source.js";
import { toLonLat } from "ol/proj.js";
import { BaseLayerSwitcher } from "../base-layer-switcher/Base-layer-switcher";

export const BaseLayerManager = () => {
	const [groups] = useObservable(getGroupEntities().pipe(map((groups) => groups.filter((g) => g.group !== "data"))));
	const [mapStore] = useObservable(getMapStore());
	const [searchParams] = useSearchParams();

	const [done, setDone] = useState(false);

	useEffect(() => {
		const map = mapStore.map;

		if (map && !done) {
			setDone(true);

			const layerList = searchParams.get("layers")?.split(",");

			groups.forEach((group) => {
				const layerGroup = new LayerGroup({
					properties: {
						name: group.name,
						id: group.name + "-" + group.id,
						type: group.group,
					},
					layers: (group.layers as any).map((l: LayerEntity) => {
						switch (l.type) {
							case "mvt":
								const layerVisibility =
									layerList === undefined || (l.group === "base" && !layerList.find((item) => item.includes("base-") && item !== "BaseLayers-base-0"))
										? l.enabled
										: !!layerList.find((i) => i === l.group + "-" + l.id);
								const mbMap = new Map({
									style: l.parameters,
									attributionControl: false,
									boxZoom: false,
									container: "map",
									doubleClickZoom: false,
									dragPan: false,
									maxZoom: 19,
									dragRotate: false,
									interactive: false,
									keyboard: false,
									pitchWithRotate: false,
									scrollZoom: false,
									touchZoomRotate: false,
								});

								const mbLayer = new Layer({
									zIndex: -1000,
									properties: {
										name: l.name,
										id: l.group + "-" + l.id,
										type: l.group,
										title: l.name,
										thumbnail: l.thumbnail,
									},
									visible: layerVisibility,
									render: function (frameState) {
										const canvas = mbMap.getCanvas();
										const viewState = frameState.viewState;

										const visible = mbLayer.getVisible();
										canvas.style.display = visible ? "block" : "none";
										canvas.style.position = "absolute";

										const opacity = mbLayer.getOpacity();
										//@ts-ignore
										canvas.style.opacity = opacity;

										// adjust view parameters in mapbox
										const rotation = viewState.rotation;
										mbMap.jumpTo({
											center: toLonLat(viewState.center) as any,
											zoom: viewState.zoom - 1,
											bearing: (-rotation * 180) / Math.PI,
										});

										//@ts-ignore
										if (mbMap._frame) {
											//@ts-ignore
											mbMap._frame.cancel();
											//@ts-ignore
											mbMap._frame = null;
										} //@ts-ignore
										mbMap._render();

										return canvas;
									},
									source: new Source({
										attributions: [
											'<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>',
											'<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
										],
									}),
								});
								return mbLayer;
							case "xyz":
								const xyz = new TileLayer({
									zIndex: -10000,
									className: l.parameters.style ? l.parameters.style + " ol-layer" : undefined,
									opacity: l.opacity ? (100 - l.opacity) / 100 : 1,
									properties: {
										type: l.group,
										title: l.name,
										id: l.group + "-" + l.id,
										thumbnail: l.thumbnail,
									},
									source: new XYZ({
										transition: 0,
										url: l.url,
										attributions: l.attributions,
									}),
									visible:
										layerList === undefined || (l.group === "base" && !layerList.find((item) => item.includes("base-") && item !== "BaseLayers-base-0"))
											? l.enabled
											: !!layerList.find((i) => i === l.group + "-" + l.id),
								});
								return xyz;
							case "wms":
								const wms = new TileLayer({
									//@ts-ignore wrong type def
									background: "white",
									zIndex: -10000,
									opacity: l.opacity ? (100 - l.opacity) / 100 : 1,
									properties: {
										type: group.group,
										title: l.name,
										id: l.group + "-" + l.id,
										thumbnail: l.thumbnail,
									},
									source: new TileWMS({
										transition: 0,
										url: l.url,
										params: {
											LAYERS: l.parameters.layers,
											VERSION: l.parameters.version,
											srs: `EPSG:${l.parameters.epsg}`,
											SERVICE: "WMS",
										},
										attributions: l.attributions,
									}),
									visible:
										layerList === undefined || (l.group === "base" && !layerList.find((item) => item.includes("base-") && item !== "BaseLayers-base-0"))
											? l.enabled
											: !!layerList.find((i) => i === l.group + "-" + l.id),
								});
								return wms;
							default:
								return undefined as any;
						}
					}),
				});

				map.addLayer(layerGroup);
			});
		}
	}, [searchParams, groups, mapStore.map, done]);

	return (
		<>
			<BaseLayerSwitcher></BaseLayerSwitcher>
		</>
	);
};
