export default function Button3Component(props: { text: string }) {
	return (
		<button
			className="w-[120px] h-[43px] bg-white rounded-7 border-npp-grey-500
      border shadow-npp-grey-sm flex justify-evenly items-center hover:outline-[#00ccf5] hover:outline hover:outline-4 hover:opacity-80 "
			type="button"
		>
			<p className="text-npp-grey-500 text-xl font-montserrat font-medium">{props.text}</p>
			<svg width="13px" height="13px" viewBox="0 0 18 18">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(-579.000000, -2771.000000)">
						<g transform="translate(100.000000, 2626.000000)">
							<g transform="translate(476.000000, 142.000000)">
								<g transform="translate(0.000000, 0.000000)">
									<polygon points="0 0 24 0 24 24 0 24"></polygon>
									<path
										d="M3,17.46 L3,20.5 C3,20.78 3.22,21 3.5,21 L6.54,21 C6.67,21 6.8,20.95 6.89,20.85 L17.81,9.94 L14.06,6.19 L3.15,17.1 C3.05,17.2 3,17.32 3,17.46 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z"
										fill="#1D1D1D"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
	);
}
