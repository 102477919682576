import { createStore, select, setProp, withProps } from "@ngneat/elf";
import { Map } from "ol";

export interface MapStoreProps {
  map?: Map;
}

const mapStore = createStore({ name: "map" }, withProps<MapStoreProps>({}));

export function getMapStore() {
  return mapStore.pipe(select((state) => state));
}

export function updateMapStore(props: MapStoreProps) {
  for (const key in props) {
    if (Object.prototype.hasOwnProperty.call(props, key)) {
      mapStore.update(setProp(key as keyof MapStoreProps, (props as any)[key]));
    }
  }
}
