import check from "../radio-check.png";

export default function Radio1Component(props: { label: string; id: string; name: string; onChange?: (event: any) => void; checked?: boolean; inactive?: boolean }) {
	return (
		<div className="flex items-center gap-4">
			<input
				style={{
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundImage: props.checked ? `url(${check})` : "",
					backgroundSize: "8px",
				}}
				className="peer/published border-npp-grey-300 rounded-full border p-2 appearance-none 
        focus:outline-transparent 
        hover:outline hover:outline-npp-blue-500 hover:outline-2 hover:opacity-80
        active:outline active:outline-npp-blue-500 active:outline-2 active:opacity-80"
				type="radio"
				id={props.id}
				name={props.name}
				defaultChecked={props.checked}
				onChange={props.onChange}
				// onChange={() => setSelected(!selected)}
			/>
			<label className="peer-checked/published:text-npp-grey-500 text-npp-grey-400" htmlFor={props.id}>
				{props.label}
			</label>
		</div>
	);
}
