import { createStore, setProp, withProps } from "@ngneat/elf";
import { withEntities, selectAllEntities, upsertEntities } from "@ngneat/elf-entities";

export interface LinearRefResponse {
	route_id: string;
	from_point: Point;
	to_point: Point;
	measure: string;
	point: string;
	addresses: any[];
	from_measure: number;
	to_measure: number;
	geometry_wkt: string;
	openlr: any;
}

export interface Point {
	x: number;
	y: number;
}

export interface StreetProps {
	next?: string;
}

interface StreetEntities {
	routeid: string;
	min_measure?: number;
	max_measure?: number;
}

export interface StreetResponse {
	count: number;
	next: string;
	previous: null;
	results: Result[];
}

export interface Result {
	routeid: string;
	min_measure?: number;
	max_measure?: number;
}

const STORE = createStore({ name: "streets" }, withEntities<StreetEntities, "routeid">({ idKey: "routeid" }), withProps<StreetProps>({}));

export const getStreetItems = () => {
	return STORE.pipe(selectAllEntities());
};

export const updateStreetItems = (results: Result[], next: string) => {
	STORE.update(upsertEntities(results), setProp("next", next));
};
