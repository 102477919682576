import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalSettingsContext } from "../../Settings";
import { DataLayersDatePicker } from "../Data-layers-datepicker";
import { LayerSwitcherLayerList } from "./Layer-switcher-layer-list";
import { useObservable } from "@ngneat/use-observable";
import { StyleLayerProp, getStyleItems } from "../Style-layer.repository";
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";

export const DataLayerFilterValue = createContext("");

export default function LayerSwitcherWrapper() {
	const [show, setShow] = useState(false);
	const [value, setValue] = useState("");
	const [items] = useObservable(getStyleItems());
	const { system_code } = useContext(GlobalSettingsContext);
	const [t] = useTranslation();

	const filterData = (items: StyleLayerProp[], value: string) => {
		return items.filter((i) => {
			if (removeDiacritics(i.name).toLowerCase().includes(removeDiacritics(value.toLowerCase()))) return true;
			const layers = i.layers.filter((l) => removeDiacritics(l.name).toLowerCase().includes(removeDiacritics(value.toLowerCase())));
			return layers.length > 0 ? layers : false;
		});
	};

	function removeDiacritics(input: string) {
		const normalized = input.normalize("NFD");
		let output = "";
		let i = 0;
		let j = 0;
		while (i < input.length) {
			output += normalized[j];
			j += input[i] === normalized[j] ? 1 : 2;
			i++;
		}
		return output;
	}

	return (
		<section id="data-layer-switcher-section" className="flex flex-col min-h-0 z-1 rounded-b p-4 min-w-0 pointer-events-auto h-full">
			<div className="flex justify-between gap-2 my-1">
				<p className="font-semibold text-npp-grey-500 uppercase">{t("dataLayers")}</p>
				<button onClick={() => setShow(!show)} className="font-medium text-npp-blue-500 flex items-center gap-2">
					<SearchOutlined rev={undefined} />
					<span className="underline">{t("searchInLayers")}</span>
					{show ? (
						<CaretUpOutlined style={{ fontSize: "12px" }} className="mt-0.5" rev={undefined} />
					) : (
						<CaretDownOutlined style={{ fontSize: "12px" }} className="mt-0.5" rev={undefined} />
					)}
				</button>
			</div>
			{show && (
				<div className="flex relative justify-between gap-2 mb-1">
					<input
						onChange={(e) => setValue(e.target.value)}
						className="p-1 px-2 border-2 placeholder:pl-1 border-neutral-300 rounded-sm focus:ring hover:ring-2 ring-npp-blue-500 focus:outline-none w-full"
						placeholder={t("searchLayers") ?? ""}
						value={value}
						type="input"
					/>
					{value && (
						<div className="absolute inset-y-0 right-0 flex items-center mr-1">
							<button
								onClick={() => {
									setValue("");
								}}
								type="button"
								className="inline-flex"
							>
								<CloseOutlined className="mr-1" rev={undefined} style={{ color: "#4d4f50", fontSize: "16px" }} />
							</button>
						</div>
					)}
					{system_code === "SIPR" && <DataLayersDatePicker></DataLayersDatePicker>}
				</div>
			)}
			<LayerSwitcherLayerList items={filterData(items, value)} styleLayers={items} expand={!!value}></LayerSwitcherLayerList>
		</section>
	);
}
