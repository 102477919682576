import { useEffect } from "react";

export const CoordSearchError = ({ hide }: { hide: () => void }) => {
	useEffect(() => {
		addListener();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addListener = () => {
		document.addEventListener("click", (e) => {
			if (!document.getElementById("coord-seach-error")?.contains(e.target as any)) {
				hide();
			}
		});
	};

	return (
		<span id="coord-seach-error">
			<div className="bg-white z-50 rounded-b p-4 absolute top-20 left-4 w-[29em]">
				Tika ievadītas nederīgas koordinātes. <br />
				Pareizs formāts:
				<span className="font-semibold text-black/80"> (lat) (lon)</span>
				<br />
				Paraugs:
				<span className="font-semibold text-black/80">56.946285 24.105078</span>
			</div>
		</span>
	);
};
