import Draw from "ol/interaction/Draw";
import { useEffect, useState } from "react";
import VectorSource from "ol/source/Vector";
import { Feature, Map } from "ol";
import { getDrawStyle } from "./Draw-styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { sticky$ } from "../Map-marker-linear-ref";
import { distinctUntilChanged } from "rxjs";
import { shiftKeyOnly } from "ol/events/condition";
import { DetourSVG } from "../../shared/svg/detour-svg";

let LINE_DETOUR_CONTROL!: Draw;

const CONTROL_ID = "draw_line_detour";

export const MapMarkerDetourLineControl = ({ source, map, readOnly }: { source: VectorSource; map: Map; readOnly?: boolean }) => {
	const [active, setActive] = useState(false);
	const [t] = useTranslation();

	useEffect(() => {
		if (
			!map ||
			!source ||
			!!map
				.getInteractions()
				.getArray()
				.find((i) => i.get("id") === CONTROL_ID)
		)
			return;

		LINE_DETOUR_CONTROL = new Draw({
			type: "MultiLineString",
			source,
			maxPoints: 2,
			minPoints: 2,
			style: getDrawStyle(),
		});
		LINE_DETOUR_CONTROL.setProperties({ group: "draw", id: CONTROL_ID });
		LINE_DETOUR_CONTROL.setActive(false);

		map.addInteraction(LINE_DETOUR_CONTROL);

		LINE_DETOUR_CONTROL.on("change:active", (e) => setActive(!e.oldValue));
		LINE_DETOUR_CONTROL.on("drawend", (e) => {
			e.feature.setProperties({
				detour: true,
			});
			e.feature.setStyle((f, r) => getDrawStyle(f as Feature, r, "rgb(204, 204, 0)"));
		});
	}, [map, source]);

	useEffect(() => {
		const sub = sticky$.pipe(distinctUntilChanged()).subscribe((res) => {
			const l = LINE_DETOUR_CONTROL as any;
			if (res) {
				l.maxPoints_ = 2;
				l.freeHandCondition_ = undefined;
			} else {
				l.maxPoints_ = undefined;
				l.freeHandCondition_ = shiftKeyOnly;
			}
		});
		return () => sub.unsubscribe();
	}, []);

	return (
		<Tooltip
			title={t("addLineTooltip")}
			overlayClassName="font-bold"
			color="#4d4f50"
			placement="left"
			overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center" }}
		>
			<button
				onClick={() => {
					const newValue = !LINE_DETOUR_CONTROL.getActive();
					map
						?.getInteractions()
						.getArray()
						.filter((i) => i instanceof Draw)
						.forEach((i) => i.setActive(false));
					LINE_DETOUR_CONTROL.setActive(newValue);
				}}
				disabled={readOnly}
				className={`bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md transition-all disabled:bg-npp-grey-300 disabled:border-npp-grey-300 disabled:ring-0 ${
					active && "ring-npp-blue-500 !border-npp-grey-500 ring-4"
				}`}
			>
				<div className="relative h-6 w-6">
					<div className="absolute inset-0 -top-1 -left-1">
						<DetourSVG></DetourSVG>
					</div>
					<p className="absolute inset-0 top-1 left-1 font-semibold text-xl">A</p>
				</div>
			</button>
		</Tooltip>
	);
};
