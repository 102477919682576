import Draw from "ol/interaction/Draw";
import { useEffect, useState } from "react";
import VectorSource from "ol/source/Vector";
import { Map } from "ol";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

let POINT_CONTROL!: Draw;

const CONTROL_ID = "draw_point";

export const MapMarkerPointControl = ({ source, map, readOnly }: { source: VectorSource; map: Map; readOnly?: boolean }) => {
	const [active, setActive] = useState(false);
	const [t] = useTranslation();

	useEffect(() => {
		if (
			!map ||
			!source ||
			!!map
				.getInteractions()
				.getArray()
				.find((i) => i.get("id") === CONTROL_ID)
		)
			return;

		POINT_CONTROL = new Draw({
			type: "Point",
			source,
		});
		POINT_CONTROL.setProperties({ group: "draw", id: CONTROL_ID });
		POINT_CONTROL.setActive(false);

		map.addInteraction(POINT_CONTROL);

		POINT_CONTROL.on("change:active", (e) => setActive(!e.oldValue));
	}, [map, source]);

	return (
		<Tooltip
			title={t("addPointTooltip")}
			overlayClassName="font-bold"
			color="#4d4f50"
			placement="left"
			overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center" }}
		>
			<button
				onClick={() => {
					const newValue = !POINT_CONTROL.getActive();
					map
						?.getInteractions()
						.getArray()
						.filter((i) => i instanceof Draw)
						.forEach((i) => i.setActive(false));
					POINT_CONTROL.setActive(newValue);
				}}
				disabled={readOnly}
				className={`bg-white p-6 border-2 border-white active:ring-4 active:ring-npp-blue-500 active:border-npp-grey-500 flex items-center rounded-full shadow-md transition-all disabled:bg-npp-grey-300 disabled:border-npp-grey-300 disabled:ring-0 ${
					active && "ring-npp-blue-500 !border-npp-grey-500 ring-4"
				}`}
			>
				<span className="material-icons">place</span>
			</button>
		</Tooltip>
	);
};
