import axios from "axios";
import { useEffect } from "react";

export function getCookie(name: string) {
	if (!document.cookie) {
		return null;
	}

	const xsrfCookies = document.cookie
		.split(";")
		.map((c) => c.trim())
		.filter((c) => c.startsWith(name + "="));

	if (xsrfCookies.length === 0) {
		return null;
	}
	return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}

export const Cookies = () => {
	useEffect(() => {
		axios.interceptors.request.use(
			(config) => {
				const cookie = getCookie("csrftoken");
				if (cookie) config.headers["x-csrftoken"] = cookie;
				return config;
			},
			(error) => {
				Promise.reject(error);
			}
		);
	}, []);

	return <></>;
};
